import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { Box, CardActionArea, IconButton, Dialog, DialogActions, DialogTitle, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter, truncateText } from "../../helpers/helper.js";
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../../api';


const TripCard = ({ data, removeDeletedTrip }) => {

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClick = async () => {
    localStorage.removeItem('fetchedData');
    navigate('/travel', { state: { tripId: data.id, isTripCreating: false } });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      await api.delete(`/trip/${data.id}`);
      handleClose();
      removeDeletedTrip(data.id); // Callback function to remove deleted trip from UI
    } catch (error) {
      console.error('Error deleting trip:', error);
    }
  };

  return (
    <Card
      sx={{
        width: '100%', // Add this line
        position: 'relative',
        boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.4)',
        backgroundColor: '#EDEDED',
        transition: 'transform 0.2s ease-in-out', // Added transition for smooth hover effect
        '&:hover': {
          transform: 'scale(1.05)', // Increase size on hover
          backgroundColor: '#ffcf91',
        },
      }}
    >
      <IconButton onClick={handleOpen} sx={{ position: 'absolute', top: 0, left: 0, color: 'red', zIndex: 1  }}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          Are you sure you want to delete this trip?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>
            No
          </Button>
          <Button onClick={handleDelete}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <CardActionArea onClick={handleClick}>
        <CardMedia
          component="img"
          height={200}
          image={data.cover_photo}
          alt={data.travel_name}
          sx={{ objectFit: 'fit'}}
        />
        <CardContent sx={{ padding:"5px 20px" ,textAlign: 'center', position: 'absolute',bottom: 0, left: 0, right: 0,margin:0, display:"flex", justifyContent: 'space-between',flexDirection:"row" ,bgcolor:"rgba(0,0,0,0.5)"}}>
            <Typography gutterBottom variant="h6" component="div" sx={{color:"#FFF", padding:"2px", margin:0 ,fontWeight:"bold", fontSize: "1rem"}}>
              {data.duration_in_days} days
            </Typography>
            <Typography gutterBottom variant="h6" component="div" sx={{color:"#FFF", padding:"2px", margin:0 ,fontWeight:"bold", fontSize: "1rem"}}>
              {truncateText(data.cities.map(city => capitalizeFirstLetter(city)).join(', '), 20)}
            </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default TripCard;
