import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea, useMediaQuery, useTheme } from '@mui/material';

const ButtonCard = ({ text, onClick, width, height, color, hoverColor }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Adjust font size and card size based on screen size
  const fontSize = isMobile ? 'h6' : 'h6';
  const cardWidth = isMobile ? '250px' : width;
  const cardHeight = isMobile ? '100px' : height;

  return (
    <Card
      sx={{
        height: cardHeight,
        width: cardWidth,
        boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.4)',
        backgroundColor: color ? color : 'white',
        transition: 'transform 0.2s ease-in-out', // Added transition for smooth hover effect
        '&:hover': {
          transform: 'scale(1.05)', // Increase size on hover
          backgroundColor: hoverColor ? hoverColor : '#ffcf91',
        },
      }}
    >
      <CardActionArea onClick={onClick}>
        <CardContent sx={{ textAlign: 'center' }}>
          <Typography gutterBottom variant={fontSize} component="div">
            {text}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default ButtonCard;