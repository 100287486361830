import React, { useEffect, useState } from "react";
import Header from "../components/Header1";
import Footer from "../components/Footer";
import ProfileCard from "../components/profile/ProfileCard";
import MapComponent from "../components/profile/MapComponent";
import { Box, Typography } from '@mui/material';
import MyTrips from "../components/profile/MyTrips";
import api from '../api';



const API_KEY = 'AIzaSyBuH3oyaXCDMtr-_jJNmGJtPBlbKDf9rVI';

const ProfilePage = () => {

  const [data, setData] = useState(null);

  // Dummy user data
  const user = {
    name: "John Doe",
    age: 30,
    nationality: "American",
    email: "johndoe@gmail.com",
    profilePicture: "https://via.placeholder.com/150", // Placeholder URL
    countriesVisited: [
      { name: "USA", coordinates: { lat: 37.0902, lng: -95.7129 } },
      { name: "Canada", coordinates: { lat: 56.1304, lng: -106.3468 } },
      // Add more visited countries with their coordinates
    ],
    citiesVisited: [
      { name: "New York", coordinates: { lat: 40.7128, lng: -74.006 } },
      { name: "Toronto", coordinates: { lat: 43.65107, lng: -79.347015 } },
      { name: "Paris", coordinates: { lat: 48.8566, lng: 2.3522 } },
      { name: "Barcelona", coordinates: { lat: 41.3851, lng: 2.1734 } },
      // Add more visited cities with their coordinates
    ],
  };

  const travelPackages = [
    {id:1, text: 'Italy', content: 'Roma, Venezia, Torino', imageUrl: 'https://a.cdn-hotels.com/gdcs/production114/d887/3ef5277d-2cda-40d6-9bd5-3a7a1f7619fe.jpg' },
    {id:2, text: 'France', content: 'Paris, Marseiles, Versailes', imageUrl: 'https://cdn.pixabay.com/photo/2015/10/06/18/26/eiffel-tower-975004_640.jpg' },
    {id:3, text: 'Scandinavia', content: 'Norway, Finland, Iceland', imageUrl: 'https://i.natgeofe.com/n/22d43fba-6c4d-400d-b6fa-6a2300589c59/GettyImages-1446646686.jpg' },
    {id:4, text: 'Turkey', content: 'Istanbul, Capadocia, Antalya', imageUrl: 'https://static.independent.co.uk/2023/12/15/09/iStock-903934818.jpg?quality=75&width=1250&crop=3%3A2%2Csmart&auto=webp' },
  ]
  // Calculate total number of countries and cities visited
  const totalCountriesVisited = user.countriesVisited.length;
  const totalCitiesVisited = user.citiesVisited.length;

  useEffect(() => {
      const fetchData = async () => {
        try {
            const response = await api.get(`/profile/`); 
            setData(response.data);
                    } catch (error) {
            console.error("Error fetching data: ", error);
        }
      };
      fetchData();
  }, []);

  return (
    <div style={{ backgroundColor: "#faf0e6" }}>
      <Header></Header>
      <div style={{ minHeight: 'calc(100vh - 150px)', display: "flex", flexDirection: "column", alignItems:"center" }}>
        { data && 
          <ProfileCard data={data}/>
        }
        <div style={{ textAlign: "center" }}>
          <Typography variant='h6'>Previous Trips</Typography>
          {/*
          <Box sx={{ width: '100%', margin:"15px 0px", display:"flex", flexDirection:"column", alignItems:"center" }}>
            <TravelPackagesRow values={travelPackages}/>
          </Box>
          */}
          <Box sx={{ width: '100%', margin:"15px 0px", display:"flex", flexDirection:"column", alignItems:"center" }}>
            <MyTrips></MyTrips>
          </Box>
        </div>
        
      </div>
      <Footer></Footer>
    </div>
  );
};

export default ProfilePage;
