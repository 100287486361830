import * as React from 'react';

const ChangingText = ({ messages }) => {
    const [index, setIndex] = React.useState(0);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % messages.length);
        }, 4000);
        return () => clearInterval(interval);
    }, [messages]);

    return <div style={{ fontSize:"25px" }}>{messages[index]}</div>;
};

export default ChangingText;