// Header.js

import React, { useState, useEffect } from 'react';
import './Header1.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import SignInPopup from './SignInPopup';
import SignUpPopup from './SignUpPopup';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';


const Header = ({ refetchFavorites }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [showSignInPopup, setShowSignInPopup] = useState(false);
  const [showSignUpPopup, setShowSignUpPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState(null); // Track user authentication state
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
        if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
        // Here, add to redirect to the searched place.
    setSearchQuery('');
  };

  const handleTitleClick = () => {
    navigate('/');
  };

  const handleSignInClick = () => {
    setShowSignInPopup(true);
  };

  const handleSignUpClick = () => {
    setShowSignUpPopup(true);
  };

  const handleCloseSignInPopup = () => {
    setShowSignInPopup(false);
    navigate('/');
  };

  const handleCloseSignUpPopup = () => {
    setShowSignUpPopup(false);
    navigate('/');
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('favorite_ids');
    setUser(null);
    setAnchorEl(null);
};

  return (
    <>
    <div style={{  height:"71px "}}></div>
    <header className="header">
      <div style={{ cursor: 'pointer' }} onClick={handleTitleClick}>
      {isMobile ? (
        <img 
          src="newLogo.png" 
          alt="Logo" 
          className="app-logo" 
          style={{ height: "45px" }} 
          onClick={handleTitleClick}
        />
      ) : (
        <img 
          src="betravellinBlack.png" 
          alt="Logo" 
          className="app-logo" 
          style={{ height: "50px" }} 
          onClick={handleTitleClick}
        />
      )}
      </div>
      <nav className="auth-links">
        {user ? (
          <div>
            <div style={{ display:"flex", alignItems:"center" }}>
              <p style={{ marginRight:"5px" }}>{user.first_name ? user.first_name : "Tripper"} {user.last_name}</p>
              <Avatar alt={user.name} src={`/profilePictures/${user.profile_picture}.png`} onClick={handleAvatarClick} style={{ cursor:"pointer" }} />
            </div>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem component={Link} to="/profile" onClick={handleMenuClose}>Profile</MenuItem>
              <MenuItem component={Link} to="/savedTrips" onClick={handleMenuClose}>Saved Trips</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        ) : (
          <div>
            <a href="#signin" className="nav-link login" onClick={handleSignInClick}>Log in</a>
            <a href="#signup" className="nav-link signup" onClick={handleSignUpClick}>Sign up</a>
          </div>
        )}
      </nav>
      {showSignInPopup && <SignInPopup onClose={handleCloseSignInPopup} setUser={setUser} />} {/* Pass setUser function to SignInPopup */}
      {showSignUpPopup && <SignUpPopup onClose={handleCloseSignUpPopup} />}
    </header>
    </>
  );
}

export default Header;
