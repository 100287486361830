import React, { useState } from 'react';
import { Button, TextField, Card, Typography } from '@mui/material';
import api from '../../api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const AddEvent = ({ lat, long, tripId, dayId, onClose, onEventAdded }) => {
    const [eventType, setEventType] = useState('Point of Interest');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [placeName, setPlaceName] = useState('');
    const [googleId, setGoogleId] = useState('');
    const [isStartTimeFocused, setIsStartTimeFocused] = useState(false);
    const [isEndTimeFocused, setIsEndTimeFocused] = useState(false);
    const [startTimeError, setStartTimeError] = useState('');
    const [endTimeError, setEndTimeError] = useState('');
    const [placeNameError, setPlaceNameError] = useState('');

    const handleEventTypeChange = (type) => {
        setEventType(type);
    };

    const handleStartTimeChange = (event) => {
        setStartTime(event.target.value);
        if (event.target.value) {
            setStartTimeError('');
        }
    };

    const handleEndTimeChange = (event) => {
        setEndTime(event.target.value);
        if (event.target.value) {
            setEndTimeError('');
        }
    };

    const handleAddEvent = async () => {
        let valid = true;
        if (!startTime) {
            setStartTimeError('Start time is required');
            valid = false;
        }
        if (!endTime) {
            setEndTimeError('End time is required');
            valid = false;
        }
        if (!placeName) {
            setPlaceNameError('Place is required');
            valid = false;
        }
        if (!valid) return;

        try {
            // Send API request
            const response = await api.post('/add-event/', {
                trip_id: tripId,
                day_id: dayId,
                start_time: startTime,
                end_time: endTime,
                name: placeName,
                google_id: googleId, // Send google_id along with other data
                eventType: eventType,
            });
            
            // Check if request was successful
            if (response.status === 201) {
                // Close the AddEvent component after adding event
                                onEventAdded(response.data.day_data);
                onClose();
            } else {
                // Handle error if needed
            }
        } catch (error) {
            // Handle error if needed
            console.error('Error adding event:', error);
        }
    };

    const handleStartTimeFocus = () => {
        setIsStartTimeFocused(true);
    };

    const handleStartTimeBlur = () => {
        setIsStartTimeFocused(false);
    };

    const handleEndTimeFocus = () => {
        setIsEndTimeFocused(true);
    };

    const handleEndTimeBlur = () => {
        setIsEndTimeFocused(false);
    };

    const getButtonStyle = (eventButtonType) => {
        return eventType === eventButtonType ? 
        { backgroundColor: 'orange', color: 'white', '&:hover': { backgroundColor: 'orange'}, margin:"10px 5px" } : 
        { borderColor: 'gray', color: "gray", margin: "10px 5px" };
    };

    const handleSelect = async (address, placeId) => {
        setPlaceName(address);
        setGoogleId(placeId);
        setPlaceNameError('');
    };

    const searchOptions = {
        location: new window.google.maps.LatLng(lat, long),
        radius: 30000, // 20 km radius
    };

    return (
        <Card sx={{ padding: "20px", margin: "10px", overflow: 'visible' }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Button sx={getButtonStyle('Point of Interest')} onClick={() => handleEventTypeChange('Point of Interest')}>
                    Point of Interest
                </Button>
                <Button sx={getButtonStyle('Restaurant')} onClick={() => handleEventTypeChange('Restaurant')}>
                    Restaurant
                </Button>
            </div>
            <TextField
                fullWidth
                id="start-time"
                label="Start Time"
                type={isStartTimeFocused ? 'time' : 'text'}
                value={startTime}
                onChange={handleStartTimeChange}
                onFocus={handleStartTimeFocus}
                onBlur={handleStartTimeBlur}
                sx={{ marginBottom: '10px' }}
                error={!!startTimeError}
                helperText={startTimeError}
            />
            <TextField
                fullWidth
                id="end-time"
                label="End Time"
                type={isEndTimeFocused ? 'time' : 'text'}
                value={endTime}
                onChange={handleEndTimeChange}
                onFocus={handleEndTimeFocus}
                onBlur={handleEndTimeBlur}
                sx={{ marginBottom: '10px' }}
                error={!!endTimeError}
                helperText={endTimeError}
            />
            <PlacesAutocomplete
                value={placeName}
                onChange={(value) => {
                    setPlaceName(value);
                    if (value) {
                        setPlaceNameError('');
                    }
                }}
                onSelect={handleSelect}
                searchOptions={searchOptions}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div style={{ position: 'relative' }}>
                        <TextField
                            {...getInputProps({
                                placeholder: 'Search Places ...',
                                className: 'location-search-input',
                            })}
                            fullWidth
                            label="Search for places..."
                            sx={{ marginBottom: '10px' }}
                            error={!!placeNameError}
                            helperText={placeNameError}
                        />
                        <div className="autocomplete-dropdown-container"
                            style={{ position: 'absolute', top: '100%', left: '0', width: '100%', zIndex: '1000' }}
                        >
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: "5px" }
                                    : { backgroundColor: 'lightgrey', cursor: 'pointer', padding: "5px" };
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
            <div style={{ display: "flex", justifyContent: "end" }}>
                <Button onClick={onClose} variant="contained" color="primary" sx={{ marginRight: "10px" }}>
                    Cancel
                </Button>
                <Button
                    onClick={handleAddEvent}
                    variant="contained"
                    color="primary"
                    disabled={!startTime || !endTime || !placeName}
                >
                    Add Event
                </Button>
            </div>
        </Card>
    );
};

export default AddEvent;
