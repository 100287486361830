import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { Box, CardActionArea, useMediaQuery, useTheme } from '@mui/material';

const PreferencesCard = ({ text, isSelected, onClick, width, imageName, height }) => {
  const imageUrl = `/preferences/${imageName}`;

  const handleCardClick = () => {
    onClick(text); // Notify parent component about the click event with the text value
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Adjust font size based on screen size
  const fontSize =  isMobile ? 'subtitle1' : 'h6';

  return (
    <Card
      sx={{
        width: width,
        boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.4)',
        backgroundColor: isSelected ? '#ffa94d' : 'white',
        transition: 'transform 0.2s ease-in-out', // Added transition for smooth hover effect
        '&:hover': {
          transform: 'scale(1.05)', // Increase size on hover
          backgroundColor: isSelected ? '#ffa94d' : '#ffcf91',
        },
      }}
    >
      <CardActionArea onClick={handleCardClick}>
        <Box sx={{ padding:'10px' }}>
        <CardMedia
          component="img"
          height={height}
          image={imageUrl}
          alt={text}
          sx={{ objectFit: 'contain' }}
        />
        </Box>
        <CardContent sx={{ textAlign: 'center' }}>
          <Typography gutterBottom variant={fontSize} component="div">
            {text}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default PreferencesCard;