import React, { useState, useEffect } from 'react';
import PreferencesSelector from '../components/preferences/PreferencesSelector';
import PreferencesSingleSelector from '../components/preferences/PreferencesSingleSelector';
import ButtonCard from '../components/preferences/ButtonCard';
import { Box, Typography } from '@mui/material';
import Header from '../components/Header1';
import Footer from '../components/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
const PreferencesPage = () => {
  const [selectedBudget, setSelectedBudget] = useState(null);
  const [selectedVisitPreferences, setSelectedVisitPreferences] = useState([]);
  const [selectedFoodPreferences, setSelectedFoodPreferences] = useState([]);
  const [selectedHotelPreferences, setSelectedHotelPreferences] = useState([]);
  const [selectedTripPreferences, setSelectedTripPreferences] = useState(null);
  const navigate = useNavigate();

  const location = useLocation();
  const { state } = location || {}; // Destructure 'state' or set it to an empty object if 'location' is undefined
  const searchData = state ? JSON.parse(state.searchData) : null;

  const handleBudgetChange = (selectedOption) => {
    setSelectedBudget(selectedOption);
  };

  const handleVisitPreferencesChange = (selectedOptions) => {
    setSelectedVisitPreferences(selectedOptions);
  };

  const handleFoodPreferencesChange = (selectedOptions) => {
    setSelectedFoodPreferences(selectedOptions);
  };

  const handleHotelPreferencesChange = (selectedOptions) => {
    setSelectedHotelPreferences(selectedOptions);
  };

  const handleTripPreferencesChange = (selectedOptions) => {
    setSelectedTripPreferences(selectedOptions);
  };

  const fetchTripData = async (searchInfo, preferences) => {
    var search_params = {}
    search_params.name = "string"; // placeholder
    search_params.origin_city = searchInfo.originCity; //placeholder
    search_params.cities = [];
    const info = searchInfo.cities;
    for (let i = 0; i < info.length; i++) {
      var city_data = {
        "name": info[i].place,
        "start_date": info[i].dateRange.startDate.split('').slice(0, 10).join(''), // getting just the day info for now.
        "end_date": info[i].dateRange.endDate.split('').slice(0, 10).join(''),
      }
      search_params.cities.push(city_data)
    } 

    search_params.acc_subratings = [];
    search_params.restaurant_type = [];
    search_params.poi_type = [];
    search_params.trip_type = "";
    search_params.budget = "";
    if (preferences){
      if (selectedBudget !== null){
        search_params.budget = selectedBudget
      }
      if (selectedTripPreferences !== null){
        search_params.trip_type = selectedTripPreferences
      }
      if (selectedFoodPreferences.length !== 0){
        search_params.restaurant_type = selectedFoodPreferences
      }
      if (selectedHotelPreferences.length !== 0){
        search_params.acc_subratings = selectedHotelPreferences
      }
      if (selectedVisitPreferences.length !== 0){
        search_params.poi_type = selectedVisitPreferences
      }
    };

    const serializedSearchParams = JSON.stringify(search_params);
    localStorage.removeItem('fetchedData');
    navigate('/travel', { state: { searchParams: serializedSearchParams, isTripCreating: true } });
  }

  const handleContinueClick = async (with_preferences) => {
    try {
      fetchTripData(searchData, with_preferences);
    } 
    catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const myFontSize = '1.4rem';

  const budgetPreferences = [
    { text: 'Low Budget', imageName: 'lowBudget2.png' },
    { text: 'Medium Budget', imageName: 'mediumBudget2.png' },
    { text: 'Luxury', imageName: 'highBudget2.png' },
  ]

  const visitPreferences = [
    { text: 'No Preference', imageName: 'noPreference.png' },
    { text: 'Museum', imageName: 'museum.png' },
    { text: 'Nature', imageName: 'nature.png' },
    { text: 'History', imageName: 'history.png' },
    { text: 'Night Life', imageName: 'nightLife.png' },
    { text: 'Aquarium/Zoo', imageName: 'zoo.png' },
    { text: 'Art Gallery', imageName: 'artGallery.png' },
    { text: 'Amusement Park', imageName: 'amusementPark.png' },
  ]

  const foodPreferences = [
    { text: 'No Preference', imageName: 'noPreferences2.png' },
    { text: 'Local', imageName: 'local.png' },
    { text: 'Seafood', imageName: 'seafood.png' },
    { text: 'Street Food', imageName: 'streetFood.png' },
    { text: 'Cafe', imageName: 'cafe.png' },
    { text: 'Vegan', imageName: 'vegan.png' },
    { text: 'Vegetarian', imageName: 'vegetarian.png' },
  ]

  const hotelPreferences = [
    { text: 'No Preference', imageName: 'noPreferences3.png' },
    { text: 'Sleep Quality', imageName: 'sleepQuality.png' },
    { text: 'Location', imageName: 'location.png' },
    { text: 'Rooms', imageName: 'rooms.png' },
    { text: 'Service', imageName: 'service.png' },
    { text: 'Value', imageName: 'value.png' },
    { text: 'Cleanliness', imageName: 'cleanliness.png' },
  ]

  const tripPreferences = [
    { text: 'Business', imageName: 'business.png' },
    { text: 'Couples', imageName: 'couple.png' },
    { text: 'Solo travel', imageName: 'solo.png' },
    { text: 'Family', imageName: 'family.png' },
    { text: 'Friends getaway', imageName: 'friends.png' },
  ]

  return (
    <div style={{ backgroundColor: "#faf0e6" }}>
    <Header></Header>

    <Box sx={{ width: '100%', margin:"0px", display:"flex", flexDirection:"column", alignItems:"center" }}>

      <Box sx={{ height:"50px" }}></Box>
      <ButtonCard text={"Continue Without Preferences"} onClick={() => handleContinueClick(false)} width={'400px'} height={'60px'}></ButtonCard>
      <Box sx={{ height:"70px" }}></Box>
      <div style={{ width: '80%', margin: '10px auto', textAlign: 'center', position: 'relative' }}>
        <hr style={{ width: '100%', borderTop: '1px solid #ccc', margin: '0' }} />
        <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: '#faf0e6', padding: '0 10px' }}>OR</span>
      </div>

      <Box sx={{ height:"50px" }}></Box>
      <Typography variant="h1" sx={{ fontSize: myFontSize, color: '#333', margin: '20px', fontWeight:"bold" }}>
        Trip Type
      </Typography>
      <PreferencesSingleSelector values={tripPreferences} selectedOption={selectedTripPreferences} onChange={handleTripPreferencesChange} />

      <Box sx={{ height:"50px" }}></Box>
      <Typography variant="h1" sx={{ fontSize: myFontSize, color: '#333', margin: '20px', fontWeight:"bold" }}>
        Visit Preferences
      </Typography>
      <PreferencesSelector values={visitPreferences} selectedOptions={selectedVisitPreferences} onChange={handleVisitPreferencesChange} />

      <Box sx={{ height:"50px" }}></Box>
      <Typography variant="h1" sx={{ fontSize: myFontSize, color: '#333', margin: '20px', fontWeight:"bold" }}>
        Food Preferences
      </Typography>
      <PreferencesSelector values={foodPreferences} selectedOptions={selectedFoodPreferences} onChange={handleFoodPreferencesChange} />

      <Box sx={{ height:"50px" }}></Box>
      <Typography variant="h1" sx={{ fontSize: myFontSize, color: '#333', margin: '20px', fontWeight:"bold" }}>
        Hotel Preferences
      </Typography>
      <PreferencesSelector values={hotelPreferences} selectedOptions={selectedHotelPreferences} onChange={handleHotelPreferencesChange} />

      <Box sx={{ height:"50px" }}></Box>
      <Typography variant="h1" sx={{ fontSize: myFontSize, color: '#333', margin: '20px', fontWeight:"bold" }}>
        Budget
      </Typography>
      <PreferencesSingleSelector values={budgetPreferences} selectedOption={selectedBudget} onChange={handleBudgetChange} />

      <Box sx={{ height:"100px" }}></Box>
      <ButtonCard text={"Continue with Preferences"} onClick={() => handleContinueClick(true)} width={'400px'} height={'60px'} color={"#8AE38A"} hoverColor={"#5DBD5D"}></ButtonCard>
    </Box>  

    <Box sx={{ height:"50px" }}></Box>
    <Footer></Footer>
    </div>
  );
}

export default PreferencesPage;
