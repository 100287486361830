import React, { useState } from 'react';
import TravelPackageCard from './TravelPackageCard';
import TravelPackagePopup from './TravelPackagePopup';


const TravelPackagesRow = ({ values, saved, favorite_ids }) => {

  const [chosenIndex, setChosenIndex] = useState(null);
  const userExists = localStorage.getItem('user') ? true : false;
  
  const handleCardClick = (index) => {
    setChosenIndex(index);
  }

  const handleFavourite = (id) => {
    if (userExists){
      if(favorite_ids != null && favorite_ids.includes(id)){
        return true;
      }
    }
    return false;
  }

  return (
      <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '80%', gap: '30px', justifyContent:'center', alignItems:"center" }}>
        {values.map((value, index) => (
          <TravelPackageCard
            key={index}
            id={value.id}
            userExists={userExists}
            isFavourite={saved ? true : handleFavourite(value.id)}
            onClick={() => handleCardClick(index)}
            imageUrl={value.travel_cover_image}
          />
        ))}
        {chosenIndex != null && <TravelPackagePopup data={values[chosenIndex]} onClose={() => setChosenIndex(null)} />}
      </div>
  );
}

export default TravelPackagesRow;