import React, { useState, useEffect } from 'react';
import { Button, TextField, Grid, Avatar, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api from '../api'; // import your api here
import Header from "../components/Header1";
import Footer from "../components/Footer";
import AvatarGrid from '../components/profile/AvatarGrid';
import { useMediaQuery, useTheme } from '@mui/material';

const EditProfile = () => {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [bio, setBio] = useState('');
  const [profilePicture, setProfilePicture] = useState(1);
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchData = async () => {
      try {
          const response = await api.get(`/profile/edit`);
          setName(response.data.first_name);
          setSurname(response.data.last_name);
          setBio(response.data.profile.about);
          setProfilePicture(response.data.profile.profile_picture);
      } catch (error) {
          console.error("Error fetching data: ", error);
      }
    };
    fetchData();
  }, []);

  const handleSave = async () => {
    try {
      const response = await api.put(`/profile/edit/`, {
        first_name: name,
        last_name: surname,
        about: bio,
        profile_picture: profilePicture,
      });
      localStorage.setItem('user', JSON.stringify(response.data));
      navigate('/profile');
    } catch (error) {
      console.error("Error updating profile: ", error);
    }
  };

  const handleCancel = () => {
    navigate('/profile');
  };

  return (
    <div style={{ backgroundColor: "#faf0e6" }}>
        <Header></Header>
        <Box sx={{ minHeight: 'calc(100vh - 150px)', display:"flex", flexDirection:"column", alignItems:"center",  }}>
          <Typography variant="h4" align="center" fontWeight="bold" marginBottom="50px" marginTop={isMobile ? "5vh" : "10vh"}>Edit Profile</Typography>
          <Grid container spacing={2} direction={isMobile ? 'column' : 'row'} justify="center" alignItems="center">
              <Grid item xs={6} sx={{ display:"flex", alignItems:"center", justifyContent:"right" }}>
                  <AvatarGrid onSelect={setProfilePicture} selectedId={profilePicture} />
              </Grid>
              <Grid item xs={6}>
                  <Grid container spacing={2} direction="column" alignItems="left" marginLeft = {isMobile ? "0" : "20px"}>
                      <Grid item>
                        <TextField 
                          label="Name" 
                          value={name} 
                          onChange={e => setName(e.target.value)} 
                          sx={{ width: isMobile ? "90%" : "50%" }} 
                        />
                      </Grid>
                      <Grid item>
                        <TextField 
                          label="Surname" 
                          value={surname} 
                          onChange={e => setSurname(e.target.value)} 
                          sx={{ width: isMobile ? "90%" : "50%" }} 
                        />
                      </Grid>
                      <Grid item>
                        <TextField 
                          label="Bio" 
                          value={bio} 
                          onChange={e => setBio(e.target.value)} 
                          multiline 
                          rows={4} 
                          inputProps={{ maxLength: 300 }} 
                          sx={{ width: isMobile ? "90%" : "50%" }} 
                        />
                      </Grid>
                      <Grid item sx={{ marginBottom: "30px" }}>
                          <Button variant="outlined" 
                            sx={{ 
                              marginRight:"10px",
                              mt: 'auto', 
                              borderColor: '#ff8000', 
                              color: '#ff8000',
                              '&:hover': { 
                                borderColor: '#ff8000', 
                                bgcolor: '#ffcc99' 
                              }
                            }} 
                            onClick={handleCancel}
                          >
                            Cancel
                          </Button>
                          <Button variant="contained" 
                                  sx={{ 
                                    mt: 'auto', 
                                    bgcolor: '#ff8000', 
                                    '&:hover': { 
                                      bgcolor: '#ffa94d' 
                                    }
                                  }} 
                                  onClick={handleSave}
                          >
                            Save Changes
                          </Button>
                      </Grid>
                  </Grid>
              </Grid>
          </Grid>
        </Box>
        <Footer></Footer>
    </div>
  );
};

export default EditProfile;