// SignInPopup.js

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './SignInPopup.css';
import api from '../api';
import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin} from '@react-oauth/google';
import { useMediaQuery, useTheme } from '@mui/material';

function SignInPopup({ onClose, setUser }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const theme = useTheme(); // Add this line
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Add this line

  const handleSubmit = (e) => {
    e.preventDefault();
    api.post('/token/', { username, password })
       .then(response => {
           const { access, refresh } = response.data;
           localStorage.setItem('access_token', access);
           localStorage.setItem('refresh_token', refresh);
           api.get('/header/').then(response => {
            const user = response.data;
            localStorage.setItem('user', JSON.stringify(user));
            setUser( user ); // Simplified user object
           })
           api.get('/package-trip/favorites-ids')
           .then(response => {
            const favorite_ids = response.data.response_data;
            localStorage.setItem('favorite_ids', JSON.stringify(favorite_ids));
           }).catch(error => console.error('Favorite Ids error:', error));
           onClose();
       })
       .catch(error => console.error('Authentication error:', error));
    
};

const googleLogin = useGoogleLogin({
  onSuccess: tokenResponse => handleGoogleSuccess(tokenResponse),
  flow: 'auth-code'
});

const handleGoogleSuccess = (response) => {
  const authorizationCode = response.code
  
  api.post('/google/', { code: authorizationCode })  // Make sure your backend endpoint is correct
     .then(response => {
         const { username, access_token, refresh_token } = response.data;
         localStorage.setItem('access_token', access_token);
         localStorage.setItem('refresh_token', refresh_token);
         const user = { "username": username };
         localStorage.setItem('user', JSON.stringify(user));
         setUser(user);
                           onClose();
     })
     .catch(error => console.error('Google sign-in error:', error));
};

const handleGoogleFailure = (error) => {
  console.error('Google Sign In was unsuccessful. Try Again Later.', error);
};

  return (
    <>
    {!isMobile && (
    <div className="signin-popup-container">
    <div className="signin-overlay" onClick={onClose}></div>
    <div className="signin-popup">
      <div className="close-btn" onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <h2>Sign In</h2>
      <form onSubmit={handleSubmit} style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }}>
        <input type="username" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required />
        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        <div className="signin-options">
          <button className="submit-button"type="submit">Sign In</button>
          <button className="forgot-password">Forgot Password?</button>
        </div>
        
      </form>
    </div>
  </div>
    )}
{isMobile && (
  <div className="signin-popup-container">
    <div className="signin-overlay" onClick={onClose}></div>
    <div className="signin-popup mobile">
      <div className="close-btn" onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <h2>Sign In</h2>
      <form onSubmit={handleSubmit}>
        <input type="username" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required />
        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        <div className="signin-options mobile">
          <button className="submit-button" type="submit">Sign In</button>
          <button className="forgot-password">Forgot Password?</button>
        </div>
      </form>
    </div>
  </div>
)}


    </>
  );
}

export default SignInPopup;
