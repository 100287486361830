import React, { useState, useEffect } from "react";
import Banner from "../components/Banner";
import SearchBar from "../components/SearchBar.js";
import Header from "../components/Header1.js";
import Footer from "../components/Footer.js";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TravelPackagesRow from '../components/travelPackage/TravelPackagesRow';
import api from "../api.js";
import { useMediaQuery, useTheme } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';

const Homepage = () => {
  const [cities, setCities] = useState([]);
  const [error, setError] = useState(null);

  const handleErrorChange= (error) => {
    setError(error);
  };

  useEffect(() => {
    if (error) {
        toast.error(error, {
            autoClose: 2500,
            onClose: () => setError(null),
            pauseOnHover:false,
        });
    }
  }, [error]);

  const favorite_ids = JSON.parse(localStorage.getItem('favorite_ids'));
  const [travelPackages, setTravelPackages] = useState([]);

  useEffect(() => {
    api.get('/package-trip/')
      .then(response => setTravelPackages(response.data.response_data))
      .catch(error => console.error('Error:', error));
  }, []);

  useEffect(() => {
    // Fetch cities from cities.txt
    fetch('/output.txt')
      .then(response => response.text())
      .then(text => {
        const citiesArray = text.split('\n').map(city => city.trim());
        setCities(citiesArray);
      })
      .catch(error => console.error('Error fetching cities:', error));
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  // Adjust font size and layout based on screen size
  const fontSize = isMedium ? isMobile ? 'h6' : 'h5' : 'h4';
  const flexDirection = isMedium ? 'column' : 'row';

  return (
    <>
      <div style={{ backgroundColor: "#faf0e6" }}>
        <div>
          <Header></Header>
        </div>

        {error && (
        <ToastContainer></ToastContainer>
        )}

        <div style={{ minHeight: 'calc(100vh - 150px)' }}>
          <Box sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "30px 0",
              flexDirection: flexDirection,
          }}>
              <img src="/assets/homeImage2.png" alt="Image" style={{ maxWidth: isMedium ? isMobile ? "100%" : "80%" : "100%", maxHeight: isMedium ? "100%" : "35vh" }}/>
              <div>
                { isMedium ?
                <Typography variant={fontSize} component="div" gutterBottom sx={{ fontFamily: "cursive" }}>
                    You dream, We plan!
                </Typography>
                :
                <>
                <Typography variant={fontSize} component="div" gutterBottom sx={{ fontFamily: "cursive" }}>
                    You dream,
                </Typography>
                <Typography variant={fontSize} component="div" sx={{ marginLeft: "3vw", fontFamily: "cursive" }}>
                    We plan!
                </Typography>
                </>
                }
              </div>
          </Box>
        
        
          <SearchBar citiesArray={cities} handleError={handleErrorChange}></SearchBar>
          <Box sx={{ width: '100%', padding:"50px 0px", display:"flex", flexDirection:"column", alignItems:"center" }}>
              <TravelPackagesRow values={travelPackages} favorite_ids={favorite_ids}/>
          </Box>
        </div>
      </div>
      <div>
        <Footer></Footer>
      </div>
    </>
  );
};

export default Homepage;