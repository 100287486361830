import logo from './logo.svg';
import React, { useEffect } from 'react';
import './App.css';
import FilterSidebar from './components/FilterSidebar.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage.js'
import PreferencesPage from './pages/PreferencesPage.js';
import TravelPage from './pages/TravelPage.js';
import PackageTravelPage from './pages/PackageTravelPage.js';
import ProfilePage from './pages/ProfilePage.js';
import TravelPackagesPage from './pages/TravelPackagesPage.js';
import PopularTripsPage from './pages/PopularTripsPage.js';
import SavedTripsPage from './pages/SavedTripsPage.js';
import EditProfile from './pages/EditProfilePage.js';
import { GoogleOAuthProvider } from '@react-oauth/google';
import api from './api.js';




function App() {

  useEffect(() => {
    const checkUser = async () => {
      const user = localStorage.getItem('user');
      if (user) {
        try {
          const response = await api.get('/header/');
          const userData = response.data;
          localStorage.setItem('user', JSON.stringify(userData));
                  } catch (error) {
          if (error.response && error.response.status === 401) {
            try {
              const refreshResponse = await api.post('/token/refresh', {
                refresh: localStorage.getItem('refresh_token')
              });
              const { access } = refreshResponse.data;
              localStorage.setItem('access_token', access);
                          } catch (error) {
              localStorage.removeItem('user');
              localStorage.removeItem('refresh_token');
              localStorage.removeItem('access_token');
                          }
          } else {
            console.error('An error occurred while fetching user data:', error);
          }
        }
      }
    };

    checkUser();
  }, []);

  return (
    // TODO: discuss this object.
    <GoogleOAuthProvider clientId="84927348185-07io12k2ut7i7ogbeddc4845n07gfq8o.apps.googleusercontent.com">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/preferences" element={<PreferencesPage />} />
          <Route path="/travel" element={<TravelPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          {/* Add more routes as needed */}
          <Route path="/packageTravel" element={<PackageTravelPage />} />
          <Route path="/popular" element={<PopularTripsPage />} ></Route>
          <Route path="/savedTrips" element={<SavedTripsPage />} ></Route>
          <Route path="/edit-profile" element={<EditProfile />} />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
