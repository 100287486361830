import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { Box, CardActionArea } from '@mui/material';
import { capitalizeFirstLetter } from '../../helpers/helper';

const DayCard = ({id, text, onClick, imageUrl, width, height }) => {

  return (
    <Card
      sx={{
        minHeight: height,
        minWidth: width,
        boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.4)',
        backgroundColor: '#EDEDED',
        transition: 'transform 0.2s ease-in-out', // Added transition for smooth hover effect
        '&:hover': {
          transform: 'scale(1.05)', // Increase size on hover
          backgroundColor: '#ffcf91',
        },
      }}
    >
      <CardActionArea onClick={() => onClick(id)}>
        <CardMedia
          component="img"
          height={height}
          image={imageUrl}
          alt={text}
          sx={{ objectFit: 'fit' }}
        />
        <CardContent sx={{ padding:"5px 20px" ,textAlign: 'center', position: 'absolute',bottom: 0, left: 0, right: 0,margin:0, display:"flex", justifyContent: 'space-between',flexDirection:"row"}}>
          <Typography gutterBottom variant="h6" component="div" sx={{color:"#FFF", padding:"2px", margin:0 ,fontWeight:"bold"}}>
            Day {id}
          </Typography>
          <Typography gutterBottom variant="h6" component="div" sx={{color:"#FFF", padding:"2px", margin:0 ,fontWeight:"bold"}}>
            {capitalizeFirstLetter(text)}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default DayCard;
