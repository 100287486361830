import React, {useState, useEffect} from 'react';
import DayCard from './DayCard';
import { useMediaQuery, useTheme } from '@mui/material';

const DaysBar = ({ data, onClick }) => {

    const [daysBars, setDaysBars] = useState([]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const fetchDaysBars = async () => {
            try {
                const response = await fetch('YOUR_API_ENDPOINT_HERE');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setDaysBars(data.daysBars); // Assuming the API response has a property named daysBars containing the list
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchDaysBars();
    }, []); // Empty dependency array to run the effect only once on component mount

    return (
        <>
        <h1 style={{ textAlign:"center" }}>Your Travel</h1>
        <div style={{ width: "100%", display:"flex", justifyContent:"center"}}>
        <div style={{ display: 'flex', flexDirection: "column", flexWrap: 'wrap', gap:"20px", justifyContent:'center', alignItems:"center" }}>
            {data.travel_days.map((bar, index) => (
                <DayCard key={index + 1} id={index +  1} text={bar.city} 
                    imageUrl={bar.image_url && bar.image_url.trim() !== "" ? bar.image_url : "placeholders/cityPlaceholder.png"} 
                    onClick={onClick} width={isMobile ? "90vw" : "20vw"} height={"150px"} />
            ))}
        </div>
        </div>
        <div style={{ height: "50px" }}></div>
        </>
    );
}

export default DaysBar;