import React, { useState, useEffect } from "react";
import Banner from "../components/Banner";
import SearchBar from "../components/SearchBar.js";
import Header from "../components/Header1.js";
import Footer from "../components/Footer.js";
import TravelPackagesRow from '../components/travelPackage/TravelPackagesRow';
import { Box, Typography } from '@mui/material';
import api from "../api.js";

const SavedTripsPage = () => {
  const [travelPackages, setTravelPackages] = useState([]);

  useEffect(() => {
    api.get('/package-trip/favorites/')
      .then(response => setTravelPackages(response.data.response_data))
      .catch(error => console.error('Error:', error));
  }, []);

  return (
    <>
      <div>
        <Header></Header>
      </div>
      <div style={{ minHeight: 'calc(100vh - 150px)', backgroundColor: "#faf0e6" }}>
        <Box sx={{ width: '100%', padding:"15px 0px", display:"flex", flexDirection:"column", alignItems:"center" }}>
          <Typography variant="h2" component="h2" gutterBottom>
            Saved Trips
          </Typography>
          {travelPackages.length === 0 ? (
            <Typography variant="h6" component="h6" gutterBottom>
              You have not saved any trips.
            </Typography>
          ) : (
            <TravelPackagesRow values={travelPackages} saved={true}/>
          )}
        </Box>
      </div>
      <div>
        <Footer></Footer>
      </div>
    </>
  );
};

export default SavedTripsPage;