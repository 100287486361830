import React, { useState } from 'react';
import {
  Box, Stepper, Step, StepLabel, StepContent,
  Typography, IconButton, SvgIcon, Button
} from '@mui/material';
import PoiView from './PoiView';
import AccomodationsView from './AccomodationsView';
import RestaurantView from './RestaurantView';
import FlightsView from './FlightsView';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../../api';
import EventEditor from './EventEditor';

const PlusIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M12 6v12m-6-6h12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

const MinusIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M6 12h12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

const DayView = ({ tripDayData, isFirstDay, onEventDelete, onEventEdit, isPackage}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isEditorOpen, setEditorOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleStepClick = (stepIndex) => {
    setActiveStep(activeStep === stepIndex ? null : stepIndex);
  };

  const openEditor = (event) => {
    setSelectedEvent(event);
    setEditorOpen(true);
  };

  const handleDeletion = async (id, type) => {
    try {
      await api.delete(`/add-event/${id}/${type}`);
      onEventDelete(id, type);
    } catch (error) {
      console.error('Error deleting trip:', error);
    }
  };

  const handleUpdateEvent = async (id, type, startTime, endTime) => {
                try {
      const response = await api.put(`/update-event/${id}/`, {
        start_time: startTime,
        end_time: endTime,
        type: type,
      });

      if (response.status === 200) {
        onEventEdit(response.data.day_data);
        closeEditor();
      } else {
        // Handle error if needed
      }
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };

  const closeEditor = () => {
    setEditorOpen(false);
    setSelectedEvent(null);
  };

  const formatData = (day_data) => {
    let steps = [];

    if (day_data.flights && isFirstDay) {
      steps.push({ label: "Flights", flights: day_data.flights, type: "flight" });
    }

    if (day_data.restaurants) {
      for (const restaurantKey in day_data.restaurants) {
        const restaurant = day_data.restaurants[restaurantKey];
        const start_time = restaurant.start_time.slice(0, -3);
        const end_time = restaurant.end_time.slice(0, -3);
        steps.push({
          label: restaurant.restaurant.name,
          day_restaurant_id: restaurant.id,
          ...restaurant.restaurant,
          start_time,
          end_time,
          timeRange: `${start_time}-${end_time}`,
          type: "restaurant",
        });
      }
    }

    if (day_data.points_of_interest) {
      for (const poiKey in day_data.points_of_interest) {
        const poi = day_data.points_of_interest[poiKey];
        const start_time = poi.start_time.slice(0, -3);
        const end_time = poi.end_time.slice(0, -3);
        steps.push({
          label: poi.point_of_interest.name,
          day_poi_id: poi.id,
          ...poi.point_of_interest,
          start_time,
          end_time,
          timeRange: `${start_time}-${end_time}`,
          type: "poi",
        });
      }
    }

    steps.sort((a, b) => {
      if (a.timeRange && b.timeRange) {
        const [aStartTime] = a.timeRange.split('-');
        const [bStartTime] = b.timeRange.split('-');
        return aStartTime.localeCompare(bStartTime);
      }
      return 0;
    });

    if (day_data.hotels) {
      steps.push({ label: "Hotels", accomodations: day_data.hotels, type: "accomodation" });
    }

    return steps;
  };

  const renderStepContent = (step) => {
    switch (step.type) {
      case 'poi':
        return <PoiView poi={step} />;
      case 'accomodation':
        return <AccomodationsView accomodations={step.accomodations} />;
      case 'restaurant':
        return <RestaurantView restaurant={step} />;
      case 'flight':
        return <FlightsView flights={step.flights} />;
      default:
        return null;
    }
  };

  const getEmoji = (type) => {
    switch (type) {
      case 'poi':
        return '📍';
      case 'accomodation':
        return '🛏️';
      case 'restaurant':
        return '🍽️';
      case 'flight':
        return '✈️';
      default:
        return '';
    }
  };

  return (
    <Box>
      <Box sx={{ flexGrow: 1 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {formatData(tripDayData).map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                StepIconComponent={index === activeStep ? MinusIcon : PlusIcon}
                optional={
                  <Typography variant="caption">{step.timeRange}</Typography>
                }
                onClick={() => handleStepClick(index)}
                style={{ cursor: 'pointer', color: '#FFA500', display: 'flex', flexDirection: 'row' }}
              >
                {step.label}

                {index === activeStep && step.type !== "accomodation" && step.type !== "flight" && !isPackage && (
                  <IconButton onClick={(e) => { e.stopPropagation(); openEditor(step); }} style={{ padding: '0', marginLeft: '10px' }}>
                    <EditIcon />
                  </IconButton>
                )}
                {index === activeStep && step.type !== "accomodation" && step.type !== "flight" && !isPackage
                  ? <IconButton onClick={(e) => { e.stopPropagation(); handleDeletion(step.type === "poi" ? step.day_poi_id : step.day_restaurant_id, step.type); }} style={{ padding: '0', marginLeft: '10px' }}>
                      <DeleteIcon />
                    </IconButton>
                  : <span style={{ fontSize: '1.5em', padding: '0', marginLeft: '10px', verticalAlign: 'middle' }}>{getEmoji(step.type)}</span>
                }
              </StepLabel>
              <StepContent>
                {renderStepContent(step)}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
      <EventEditor
        event={selectedEvent}
        isOpen={isEditorOpen}
        onClose={closeEditor}
        onSave={handleUpdateEvent}
      />
    </Box>
  );
};

export default DayView;
