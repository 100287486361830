import React, { useState, useEffect } from 'react';
import './SearchBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import { useMediaQuery, useTheme } from '@mui/material';
import { toast } from 'react-toastify';

const SearchBarContainer = ({citiesArray, handleError}) => {
  const [searchBars, setSearchBars] = useState([
    { id: 1, place: '', dateRange: { startDate: null, endDate: null }, people: '', focusedInput: null }
  ]);
  const [originCity, setOriginCity] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [removingBarId, setRemovingBarId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth <= 600);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const addSearchBar = () => {
    const newId = searchBars.length > 0 ? searchBars[searchBars.length - 1].id + 1 : 1;
    let lastSearchBarPlace = '';
    let lastSearchBarPeople = '';
    let lastEndDate = null;

    if (searchBars.length > 0) {
      const lastSearchBar = searchBars[searchBars.length - 1];
      if (lastSearchBar.place !== '') {
        //lastSearchBarPlace = lastSearchBar.place;
      }
      if (lastSearchBar.people !== '') {
        lastSearchBarPeople = lastSearchBar.people;
      }
      if (lastSearchBar.dateRange && lastSearchBar.dateRange.endDate) {
        lastEndDate = lastSearchBar.dateRange.endDate;
      }
    }

    const nextStartDate = lastEndDate ? lastEndDate.clone().add(1, 'days') : null;

    setSearchBars([...searchBars, {
      id: newId,
      place: lastSearchBarPlace,
      dateRange: { startDate: nextStartDate, endDate: null },
      people: lastSearchBarPeople,
      focusedInput: null
    }]);  
  };

  const removeSearchBar = (id) => {
    setRemovingBarId(id);
    setTimeout(() => {
      setSearchBars(searchBars.filter(bar => bar.id !== id));
      setRemovingBarId(null);
    }, 500); // Match the duration of your slideOutUp animation
  };

  const updateSearchBar = (id, field, value) => {
    const updatedSearchBars = searchBars.map(bar =>
      bar.id === id ? { ...bar, [field]: value } : bar
    );
    setSearchBars(updatedSearchBars);
  };

  const updateFocusedInput = (id, focusedInput) => {
    const updatedSearchBars = searchBars.map(bar =>
      bar.id === id ? { ...bar, focusedInput } : bar
    );
    setSearchBars(updatedSearchBars);
  };

  const checkParams = (searchParams) => {
    try {
      const processedData = searchParams.map(item => {
        // Check if place is not empty and dateRange is not empty
        if (item.place.trim() !== '' && item.dateRange.startDate !== null && item.dateRange.endDate !== null) {
          // If people is empty, set it to 1
          if (item.people.trim() === '') {
            item.people = 1;
          }
          return item; // Return the processed item
        } else {
          throw new Error('Invalid data format');
        }
      });
  
      // Filter out the null entries (invalid items)
      const validData = processedData.filter(item => item !== null);
  
      return validData;
    } catch (error) {
      return null;
    }
  }
  
  const handleSearch = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user) {
      handleError("You need to be logged in to create personalized trips!");
      return;
    }
    
    const searchInfo = checkParams(searchBars);
    if (searchInfo) {
      const data = { cities: searchInfo, originCity: originCity };
      const serializedData = JSON.stringify(data);
      navigate('/preferences', { state: { searchData: serializedData } });
    } else {
      handleError("Please enter necessary Travel Preferences!");
      return;
    }
  };

  return (
    <div>   
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "15px"
      }}>
      <Autocomplete
        sx={{}}
        freeSolo
        options={citiesArray}
        filterOptions={(options, params) => {
          if (params.inputValue.length < 2) {
            return [];
          }
          const filtered = options.filter((option) =>
            option.toLowerCase().startsWith(params.inputValue.toLowerCase())
          );
          return filtered.slice(0, 5);
        }}
        value={originCity || ''}
        onChange={(event, newValue) => {
          setOriginCity(newValue || '');
        }}
        renderInput={(params) => (
          <TextField
            {...params} // Spread the params object to include all necessary properties
            variant="standard"
            placeholder="Origin City (optional)"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
            sx={{
              backgroundColor: "#FFF",
              borderRadius: "30px",
              boxShadow: "1px 1px 4px 1px rgba(0, 0, 0, 0.2)",
              width: "250px",
              height: "45px",
              position: "relative",
              fontFamily: "inherit",
              fontSize: "16px",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              transition: "background-color 0.3s",
              input: { 
                textAlign: "center",
                padding: "10px 0", 
              },
            }}
          />
        )}
      />
      </Box>
      {searchBars.map((bar, index) => (
        <div key={bar.id} className={`search-bar-container ${removingBarId === bar.id ? 'slide-out' : ''}`}>
          {index === 0 && (
            <button className="add-search-bar" onClick={addSearchBar}>
              <FontAwesomeIcon icon={faPlus} />
            </button>
          )}
          {index !== 0 && (
            <button className="remove-search-bar" onClick={() => removeSearchBar(bar.id)}>
              <FontAwesomeIcon icon={faMinus} />
            </button>
          )}     
          <SearchBar
            searchInfo={bar}
            isSmallScreen={isSmallScreen}
            onSearchChange={(field, value) => updateSearchBar(bar.id, field, value)}
            onFocusChange={(focusedInput) => updateFocusedInput(bar.id, focusedInput)}
            onSearch={() => handleSearch()}
            startDateId={`startDate_${bar.id}`}
            endDateId={`endDate_${bar.id}`}
            showSearchButton={index === 0}
            place={bar.place}
            setPlace={(id, value) => updateSearchBar(id, 'place', value)}
            setPeople={(id, value) => updateSearchBar(id, 'people', value)}
            setDate={(id, startDate, endDate) => updateSearchBar(id, 'dateRange', { startDate, endDate })}
            cities={citiesArray}
          />   
        </div>
      ))}
    </div>
  );
};

const SearchBar = ({
  showSearchButton,
  setPlace,
  searchInfo,
  setPeople,
  setDate,
  onSearch,
  cities
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [focusedInput, setFocusedInput] = useState(null);
  const navigate = useNavigate();

  return (
    <div className={`search-bar`}>
      <Autocomplete
        className='search-input'
        freeSolo
        disableClearable
        options={cities}
        filterOptions={(options, params) => {
          if(params.inputValue.length<2){
            return [];
          }
          const filtered = options.filter((option) =>
            option.toLowerCase().startsWith(params.inputValue.toLowerCase())
          );
          return filtered.slice(0, 5);
        }}
        value={searchInfo.place}
        onChange={(event, newValue) => {
          setPlace(searchInfo.id, newValue || '');
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder={isSmallScreen ? 'City Name' : 'City Name'}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
            sx={{
              '& input': {
                textAlign: 'center',
              },
            }}
          />
        )}
      />
      {
        isSmallScreen && (
          <div style={{ width: "70%", height:"2px", backgroundColor:"black", opacity:"0.2", margin:"5px 0"}}></div>
        )
      }
      <div className='divider'></div>
      <DateRangePicker
        className="DateRangePicker"
        startDate={searchInfo.dateRange.startDate}
        startDateId="start_date_id"
        endDate={searchInfo.dateRange.endDate}
        endDateId="end_date_id"
        onDatesChange={({ startDate, endDate }) => setDate(searchInfo.id, startDate, endDate)}
        focusedInput={focusedInput}
        onFocusChange={focused => setFocusedInput(focused)}
        numberOfMonths={isSmallScreen ? 1 : 2}
        isOutsideRange={() => false}
        showClearDates={isSmallScreen ? false : true}
        noBorder
      />
      {!isSmallScreen && (
        <div className='divider'></div>
      )}
      {!isSmallScreen && (
        <input
          className="search-input small-width"
          type="number"
          value={searchInfo.people}
          placeholder={isSmallScreen ? 'People C.' : 'People Count'}
          onChange={(peopleInput) => setPeople(searchInfo.id, peopleInput.target.value)}
        />
      )}
      {showSearchButton && (
        <button className="search-button" onClick={onSearch}>
          <FontAwesomeIcon icon={faSearch} />
        </button>
      )}
    </div>
  );
};

export default SearchBarContainer;
