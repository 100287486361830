import React, { useState } from 'react';
import PreferencesCard from './PreferencesCard';
import { useMediaQuery, useTheme } from '@mui/material';

const PreferencesSingleSelector = ({ values, selectedOption, onChange }) => {

  const handleOptionClick = (option) => {
    onChange(option); // Notify parent component about the selection
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Adjust card size based on screen size
  const cardWidth = isMobile ? 150 : 200;
  const cardHeight = isMobile ? 100 : 140;

  return (
      <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: isMobile ? '95%' : '70%', gap: '20px', justifyContent:'center' }}>
        {values.map((value) => (
          <PreferencesCard
            key={value.text}
            text={value.text}
            isSelected={selectedOption === value.text}
            onClick={() => handleOptionClick(value.text)}
            width={cardWidth}
            imageName={value.imageName}
            height={cardHeight}
          />
        ))}
      </div>
  );
}

export default PreferencesSingleSelector;