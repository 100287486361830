import * as React from 'react';
import Snake from 'react-simple-snake';
import SnakeMobile from 'react-simple-snake-mobile';
import styled, { keyframes } from 'styled-components';
import ChangingText from './ChangingText';
import Header from '../Header1';
import Footer from "../Footer.js";
import { useMediaQuery, useTheme } from '@mui/material';

const LoadingComponent = ({isTripCreating}) => {
    const getRandomBackground = () => {
        const backgroundImages = [
            'https://travelplannerheed.s3.eu-north-1.amazonaws.com/loading_photos/sceneric_baloon.jpg',
            'https://travelplannerheed.s3.eu-north-1.amazonaws.com/loading_photos/sceneric_beach.jpg',
            'https://travelplannerheed.s3.eu-north-1.amazonaws.com/loading_photos/sceneric_east.jpg',
            'https://travelplannerheed.s3.eu-north-1.amazonaws.com/loading_photos/sceneric_mountain.jpg',
            'https://travelplannerheed.s3.eu-north-1.amazonaws.com/loading_photos/sceneric_world.jpg',
            // Add more background image paths as needed
        ];
        const randomIndex = Math.floor(Math.random() * backgroundImages.length);
                return backgroundImages[randomIndex];
    };

    const [backgroundUrl, setBackgroundUrl] = React.useState(getRandomBackground());

    const spin = keyframes`
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    `;

    const Spinner = styled.div`
      display: inline-block;
      width: 80px;
      height: 80px;
      border: 6px solid #f3f3f3;
      border-radius: 50%;
      border-top: 6px solid #ff8000;
      animation: ${spin} 2s linear infinite;
    `;

    const BackgroundContainer = styled.div`
      position: relative;
      width: 100%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-image: url(${backgroundUrl}); 
      background-size: cover;
      background-position: center;
      
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5); // opacity
        z-index: 1; // Ensure the overlay is on top of the background but below the content
      }
    `;

    const ContentContainer = styled.div`
      position: relative;
      z-index: 2; // Ensure the content is above the overlay
      width: 100%;
      height: calc(100vh - 150px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `;

    const messages = isTripCreating ? ["Your trip is being created", "We aim to create the best trip", "Have fun with the game"] : ["We are loading the trip"];
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <BackgroundContainer>
            <ContentContainer>
            <Header></Header>
            {!isSmallScreen && (
                 <>
                <div style={{ width:"100%", height: 'calc(100vh - 150px)', display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                
                <Spinner style={{ marginBottom:"10px" }} />
                <ChangingText messages={messages} />
                <div style={{ width:"35%", marginTop:"70px" }}>
                    <Snake 
                        percentageWidth={100}   // Set the width of the game board to 60% of the parent element's width
                        startSnakeSize={5}     // Initial size of the snake
                        snakeColor="#ff8000"   // Color of the snake
                        appleColor="#FF0000"   // Color of the apples
                    />
                </div>
                </div>
                </>
            )}
            {isSmallScreen && (
                <>
                <div style={{ width:"100%", height: 'calc(100vh - 150px)', display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                <Spinner style={{ marginBottom:"10px" }} />
                <ChangingText messages={messages} />
                <SnakeMobile 
                        percentageWidth={90}   // Set the width of the game board to 60% of the parent element's width
                        startSnakeSize={5}     // Initial size of the snake
                        snakeColor="#ff8000"   // Color of the snake
                        appleColor="#FF0000"   // Color of the apples
                    />
                </div>
                </>


            )}
            </ContentContainer>
            </BackgroundContainer>

        </>
    );
}

export default LoadingComponent;