import * as React from 'react';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import { Box, CardActionArea } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useMediaQuery, useTheme } from '@mui/material';
import api from '../../api';



const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const TravelPackageCard = ({id, onClick, userExists, imageUrl, isFavourite}) => {

  const [favorite, setFavorite] = React.useState(isFavourite);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCardClick = () => {
    onClick();
  };

  const toggleFavourite = () => {
    if (userExists){
            if(favorite){
        api.delete(`/package-trip/favorite-delete/${id}/`)
          .then(() => deleteFavourite(id))
          .catch(error => console.error('Error:', error));
      } else {
        api.post(`/package-trip/favorites/`, {id: id})
          .then(addFavourite(id))
          .catch(error => console.error('Error:', error));
      }
      setFavorite(!favorite);
    }
  };

  const addFavourite = (id) => {
    var favorite_ids = localStorage.getItem('favorite_ids');
    if (favorite_ids == null){
      favorite_ids = [id];
    } else {
      favorite_ids = JSON.parse(favorite_ids);
      favorite_ids.push(id);
    }
    localStorage.setItem('favorite_ids', JSON.stringify(favorite_ids));
  }

  const deleteFavourite = (id) => {
    var favorite_ids = localStorage.getItem('favorite_ids');
    if (favorite_ids != null){
      favorite_ids = JSON.parse(favorite_ids);
      favorite_ids = favorite_ids.filter((item) => item !== id);
      localStorage.setItem('favorite_ids', JSON.stringify(favorite_ids));
    }
  }

  return (
    <Card
      sx={{
        position: 'relative',
        width: isMobile ? '350px' : '400px',
        height: isMobile ? '200px' : '250px',
        boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.4)',
        backgroundColor: '#EDEDED',
        transition: 'transform 0.2s ease-in-out, width 0.6s ease-in-out, height 0.6s ease-in-out', 
        '&:hover': {
          transform: 'scale(1.03)',
        },
      }}
    >
      <CardActionArea onClick={handleCardClick} sx={{ height:"100%", display:"flex", flexDirection:"column", justifyContent:"start" }}>
        <CardMedia
          component="img"
          image={imageUrl}
          sx={{ height: isMobile ? '200px' : '250px', width:"100%", objectFit: 'fill' }}
          onClick={handleCardClick}
        />
      </CardActionArea>

        <IconButton 
          aria-label="add_to_favorites" 
          color={favorite ? 'error' : 'default'}
          sx={{ 
            position: 'absolute', 
            top: '5px', 
            right: '5px', 
          }}
          onClick={toggleFavourite}
        >
          <FavoriteIcon />
        </IconButton>
      
    </Card>
  );
}

export default TravelPackageCard;
