import React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { Typography, Box, Link, Button, useMediaQuery, useTheme } from '@mui/material';
import Rating from '@mui/material/Rating';


const getPriceLevelText = (priceLevel) => {
    switch (priceLevel) {
      case 'PRICE_LEVEL_UNSPECIFIED':
        return 'Unknown';  
      case 'PRICE_LEVEL_FREE':
        return 'Free';
      case 'PRICE_LEVEL_INEXPENSIVE':
        return 'Budget-friendly';
      case 'PRICE_LEVEL_MODERATE':
        return 'Moderate';
      case 'PRICE_LEVEL_EXPENSIVE':
        return 'Expensive';
      case 'PRICE_LEVEL_VERY_EXPENSIVE':
        return 'Very Expensive';
      default:
        return 'Price information not available';
    }
  };

const RestaurantView = ({ poi }) => {

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div style={{ display: 'flex', width: isMobileView ? "100%" : "45vw", alignItems: 'center', flexDirection: isMobileView ? 'column' : 'row' }}>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Card
          sx={{
            flex: "2",
            boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.4)',
            backgroundColor: '#EDEDED',
            transition: 'transform 0.2s ease-in-out', // Added transition for smooth hover effect
            maxHeight: isMobileView ? "30vw" : "10vw" 
          }}
        >
          <CardMedia
            component="img"
            image={poi.imageUri ? poi.imageUri : "/placeholders/poiPlaceholder.png"}
            alt={poi.label}
            sx={{ objectFit: 'cover', width: '100%', maxHeight: isMobileView ? "30vw" : "10vw" }}
          />
        </Card>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "10px" }}>
          <Rating name="read-only" value={poi.rating} precision={0.1} readOnly sx={{ fontSize: "20px" }}/>
          { poi.reviewCount 
            ? <Typography sx={{ paddingLeft: "10px", color: "gray", fontSize: "15px" }}>{poi.reviewCount} reviews</Typography>
            : <Typography sx={{ paddingLeft: "10px", color: "gray", fontSize: "15px" }}>({poi.rating})</Typography>
          }
        </div>
      </div>

      <div style={{ flex: "3", display: "flex", flexDirection: "column", margin: "0px 20px" }}>
        <Typography variant='h6'>{poi.label}</Typography>
        <Typography variant='p'>{poi.description}</Typography>
        <Typography sx={{ fontSize: "12px" ,marginTop: "10px"}}>{poi.formattedAddress}</Typography>
        { poi.priceLevel && <Typography sx={{ fontSize: "14px", color: "gray" }}>Price: {getPriceLevelText(poi.priceLevel)}</Typography> }
        { poi.workingHours && <Typography sx={{ fontSize: "14px", color: "gray" }}>Working Hours: {poi.workingHours}</Typography> }
        <Box sx={{ height: "10px" }} />
        {/*<Link href={restaurant.googleMapsUrl} target="_blank" rel="noopener noreferrer">View on Google Maps</Link>*/}
        <div style={{ display: "flex" }}>
            { poi.googleMapsUri &&
              <div style={{ display: 'inline-block' }}>
                  <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => window.open(poi.googleMapsUri, '_blank')}
                      style={{ marginBottom: '5px', fontSize: '12px' }}
                  >
                      View on Google Maps
                  </Button>
              </div>
            }
            <Box sx={{ width: "10px" }} />
            { poi.websiteUri && 
              <div style={{ display: 'inline-block' }}> {/* Show this div only if poi.websiteUrl is not null */}
                  <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => window.open(poi.websiteUri, '_blank')}
                      style={{ marginBottom: '5px', fontSize: '12px' }}
                  >
                      Visit Website
                  </Button>
              </div>
            }
        </div>
        <Box sx={{ height: "30px" }} />
      </div>
    </div>
  );
}

export default RestaurantView;
