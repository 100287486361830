import React, {useState, useEffect} from "react";
import Header from "../components/Header1.js";
import Footer from "../components/Footer.js";
import DayCard from "../components/travel/DayCard.js";
import DaysBar from "../components/travel/DaysBar.js";
import DayBar from "../components/travel/DayBar.js";
import Map from "../components/travel/Map.js";
import LoadingComponent from "../components/travel/LoadingComponent.js";
import { useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import api from '../api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { duration } from "@mui/material";
import { useMediaQuery, useTheme } from '@mui/material'; 
import IconButton from '@mui/material/IconButton';
import MapIcon from '@mui/icons-material/Map';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';



const TravelPage = () => {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedDay, setSelectedDay] = useState(null);
    const [error, setError] = useState(null);
    const mapRef = useRef(null);
    const [coordinates, setCoordinates] = useState({ lat: 40.748817, lng: -73.985428 });

    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location || {}; // Destructure 'state' or set it to an empty object if 'location' is undefined
    const searchParams = state.searchParams ? JSON.parse(state.searchParams) : null;
    const tripId = state.tripId ? JSON.parse(state.tripId) : null;   
    const theme = useTheme(); // Add this line
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Add this line

    const [viewMode, setViewMode] = useState('bar'); // Update this line

    // ... other code ...

    const switchViewMode = () => {
        setViewMode(viewMode === 'map' ? 'bar' : 'map');
    };

    // Fetch data from API when component mounts
    useEffect(() => {
      const fetchData = async () => {
          try {
                            var response = null;
              if(searchParams) {
                response = await api.post('/trip/', searchParams); // Replace with your API URL
              }
              else if (tripId) {
                response = await api.get(`/trip/${tripId}`); // Replace with your API URL
                              }
              setData(response.data);
                            setLoading(false);
              localStorage.setItem('fetchedData', JSON.stringify(response.data));
          } catch (error) {
              console.error("Error fetching data: ", error);
              if (error.response && error.response.data && error.response.data.detail) {
                  setError(error.response.data.detail);
              } else {
                  setError("An error occurred while fetching data.");
              }
              setLoading(false);
          }
      };
      const storedData = localStorage.getItem('fetchedData');
      if (!storedData) {
        fetchData();
      } else {
        setLoading(false);
        setData(JSON.parse(storedData));
      }
}, []);  

    const handleDataChange = (newData) => {
      const tempData = JSON.parse(JSON.stringify(data)); // Create a deep copy of data
      const dayIndex = tempData.response_data.travel_days.findIndex(day => day.id === newData.id);
      if (dayIndex !== -1) {
        tempData.response_data.travel_days[dayIndex] = newData;
        setData(tempData);
        updateMap(newData);
        localStorage.setItem('fetchedData', JSON.stringify(tempData));
      } else {
        console.error(`Day with id ${newData.id} not found`);
      }
    };
        


    const isFirstDay = () => { 
        if(selectedDay === null) return false
        if(selectedDay === 1) return true
        if(data.response_data.travel_days[selectedDay-1].city === data.response_data.travel_days[selectedDay-2].city) return false
        return true
    }

    const handleDayChange = (dayId) => {
  
      if (dayId !== null) {
          setSelectedDay(dayId);
          const places = data.response_data.travel_days[dayId - 1];
          updateMap(places);
      } else {
          // show coordinates of cities.
          const uniqueCities = {};

          data.response_data.travel_days.forEach(day => {
            const { city, latitude, longitude } = day;
            
            // Check if the city is not already added
            if (!uniqueCities[city]) {
              uniqueCities[city] = {"name": city, "lat":latitude, "lng":longitude };
            }
          });
          const cities = Object.values(uniqueCities);
                    setSelectedDay(null);
          mapRef.current.updateCoordinates(cities);
          mapRef.current.addMarkersForPlaces(cities, false); 
      }
    };

    const updateMap = (places) => {
      if (!mapRef.current) {
        console.error("Map is not initialized yet.");
        return;
      }

      const restaurantsToShow = places.restaurants
          .filter(restaurant => 
            restaurant.restaurant.latitude !== null && 
            restaurant.restaurant.longitude !== null
          )
          .map(restaurant => ({
            name: restaurant.restaurant.name,
            lat: restaurant.restaurant.latitude,
            lng: restaurant.restaurant.longitude
          }));
          const poisToShow = places.points_of_interest
          .filter(poi => 
            poi.point_of_interest.latitude !== null && 
            poi.point_of_interest.longitude !== null
          )
          .map(poi => ({
            name: poi.point_of_interest.name,
            lat: poi.point_of_interest.latitude,
            lng: poi.point_of_interest.longitude
          }));
      const combinedPlaces = restaurantsToShow.concat(poisToShow);
            mapRef.current.updateCoordinates(combinedPlaces);
      mapRef.current.addMarkersForPlaces(combinedPlaces, false); // false if we dont want to see lines.
    }

  const uniqueCities = {};

  if (data && data.response_data) {
    data.response_data.travel_days.forEach(day => {
      const { city, latitude, longitude } = day;
      
      // Check if the city is not already added
      if (!uniqueCities[city]) {
        uniqueCities[city] = {"lat":latitude, "lng":longitude };
      }
    });
  }
  const cities = Object.values(uniqueCities);

  
  useEffect(() => {
    if (error) {
        toast.error(error, {
            autoClose: 4000,
            onClose: () => navigate('/'),
            onClick: () => navigate('/'),
            pauseOnHover:false,
        });
    }
  }, [error, navigate]);


  if(isMobile) {
    return (
        <>
            <div>
              <Header></Header>
            </div>

            {error && (
              <ToastContainer></ToastContainer>
            )}

            {data === null ? (
                <LoadingComponent isTripCreating={state.isTripCreating}></LoadingComponent>
            ) : (
              <div style={{ position: "relative", height: "100%", overflowY:"hidden" }}>
                {/* Main content */}
                <div style={{ height: 'calc(100vh - 71px)'}}>
                    <div style={{ height: viewMode==='map' ? "100%" : 0, visibility: viewMode==='map' ? "visible" : "hidden" }}>
                        <Map ref={mapRef} coordinates={cities} mode={'view'} place={'deneme'} />   
                    </div>

                    { viewMode==='bar' &&
                      <div style={{ 
                        height: '100%',
                        backgroundColor: "#EDEDED", 
                        overflowY: 'scroll',
                        position: "relative",
                      }}>
                        <div style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%", 
                          transform: !selectedDay ? "translateX(0)" : "translateX(-100%)", 
                          transition: "transform 0.5s ease-in-out",  
                          height: !selectedDay ? "auto" : "0px"
                        }}>
                          <DaysBar data={data.response_data} onClick={handleDayChange}></DaysBar>
                        </div>

                        <div style={{ 
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          transform: selectedDay ? "translateX(0)" : "translateX(+100%)", 
                          transition: "transform 0.5s ease-in-out",  
                          height: selectedDay ? "auto" : "0px"
                        }}>
                          {selectedDay && <DayBar 
                            id={selectedDay} 
                            day_data={data.response_data.travel_days[selectedDay - 1]}
                            trip_id={data.response_data.id} 
                            isFirstDay={isFirstDay(selectedDay)}
                            onClose={() => handleDayChange(null)}
                            handleDataChange={handleDataChange}/>}
                        </div>
                      </div>
                    }
                  
                </div>

                {/* Switch view mode button */}
                <div style={{ position: "absolute", left: 20, bottom: 35 }}>
                  <IconButton onClick={switchViewMode} style={{ color:"white", backgroundColor: '#ffa94d', borderRadius: '50%', padding:"15px" }}>
                      {viewMode === 'map' ? <ViewAgendaIcon /> : <MapIcon />}
                  </IconButton>
                </div>
            </div>
            )}
        </>
    );
  };

  return (
    <>
      <div>
        <Header></Header>
      </div>

      {error && (
        <ToastContainer></ToastContainer>
      )}

      {data === null ? (
        <LoadingComponent isTripCreating={state.isTripCreating}></LoadingComponent>
      ) : (
        <div style={{ display: "flex" }}>
          {/* Side part */}
          <div style={{ 
            flex: selectedDay ? 3 : 1, 
            transition: "flex 0.5s ease-in-out", 
            minHeight: 'calc(100vh - 150px)',
            backgroundColor: "#EDEDED", 
            overflowY: 'scroll',
            maxHeight: "95vh",
            boxShadow: "2px 0px 5px rgba(0, 0, 0, 0.2)",
            position: "relative",
          }}>
            {/* Components in the side part */}
            <div style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%", 
              transform: !selectedDay ? "translateX(0)" : "translateX(-100%)", 
              transition: "transform 0.5s ease-in-out",  
              height: !selectedDay ? "auto" : "0px"
            }}>
              <DaysBar data={data.response_data} onClick={handleDayChange}></DaysBar>
            </div>

            <div style={{ 
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              transform: selectedDay ? "translateX(0)" : "translateX(+100%)", 
              transition: "transform 0.5s ease-in-out",  
              height: selectedDay ? "auto" : "0px"
            }}>
              {selectedDay && <DayBar 
                id={selectedDay} 
                day_data={data.response_data.travel_days[selectedDay - 1]}
                trip_id={data.response_data.id} 
                isFirstDay={isFirstDay(selectedDay)}
                onClose={() => handleDayChange(null)}
                handleDataChange={handleDataChange}/>}
            </div>
          </div>

          {/* Main content */}
          <div style={{ flex: 3, transition: 'flex 0.5s ease-in-out'}}>
            <div style={{ transition: 'height 0.5s ease-in-out' }}>
              <Map ref={mapRef} coordinates={cities} mode={'view'} place={'deneme'} />   
            </div>       
          </div>
        </div>
      )}
    </>
  );
};


export default TravelPage;