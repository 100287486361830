import React, { useState } from 'react';
import DayView from './DayView';
import Button from '@mui/material/Button';
import AddEvent from './AddEvent';
import EventEditor from './EventEditor';
import { capitalizeFirstLetter } from '../../helpers/helper';

const DayBar = ({ id, day_data, trip_id, isFirstDay, onClose, onPlaceClick, handleDataChange }) => {
    const [addEventOpen, setAddEventOpen] = useState(false);
    const [editEventOpen, setEditEventOpen] = useState(false); 
    const [selectedEvent, setSelectedEvent] = useState(null); // State to hold the event to be edited

    const handleEventChange = (newDayData) => {
      handleDataChange(newDayData);
          };

    const handleEventEdit = (event) => {
      setSelectedEvent(event);
      setEditEventOpen(true);
    };

    const handleEventDelete = (id, type) => {
      const storedData = localStorage.getItem('fetchedData');
      if (type === "poi") {
        day_data.points_of_interest = day_data.points_of_interest.filter(poi => poi.id !== id);
      } else if (type === "restaurant") {
        day_data.restaurants = day_data.restaurants.filter(restaurant => restaurant.id !== id);
      }
      if (storedData) {
        var parsedStoredData = JSON.parse(storedData);
        const travelDayIndex = parsedStoredData.response_data.travel_days.findIndex(day => day.city === day_data.city && day.date === day_data.date);
        if (travelDayIndex !== -1) {
          parsedStoredData.response_data.travel_days[travelDayIndex] = day_data;
          const newStoredData = JSON.stringify(parsedStoredData);
          localStorage.setItem('fetchedData', newStoredData);
        }
      } 
      handleDataChange(day_data); // Update the state with the new day data received from AddEvent
    };

    if (day_data == null) return <div></div>;

    if(day_data == null) return <div></div>
    
    return (
        <div style={{ padding: '10px', marginBottom: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <button onClick={onClose} style={{ background: 'transparent', border: 'none', cursor: 'pointer', fontSize: '50px', color: '#999' }}>×</button>
                <Button onClick={() => setAddEventOpen(true)} sx={{ background: 'transparent', border: 'none', cursor: 'pointer', fontSize: '25px', color: '#999' }}>+ Add Event</Button>
            </div>

            <div style={{ overflow: 'hidden', height: addEventOpen ? '400px' : 0, transition: "height 250ms linear 0s" }}>
                {addEventOpen && 
                <AddEvent onClose={() => setAddEventOpen(false)} dayId={day_data.id} tripId={trip_id} onEventAdded={handleEventChange} lat={day_data.latitude} long={day_data.longitude} />}
            </div>

            <h3>Day {id}</h3>
            <p>{capitalizeFirstLetter(day_data.city)}</p>

            <DayView tripDayData={day_data} isFirstDay={isFirstDay} onEventDelete={handleEventDelete} onEventEdit={handleEventChange} isPackage={false}></DayView>
            <div style={{ height: '100px' }}></div>

            {/* Additional content or actions can be added here */}
        </div>
    );
};

export default DayBar;
