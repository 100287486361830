import React from 'react';
import { IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import {useMediaQuery , useTheme} from '@mui/material'
const TraveldataPopup = ({ data, onClose }) => {
  const navigate = useNavigate(); 
  const theme = useTheme();
  const handleClick = async () => {
    localStorage.removeItem('fetchedPackageData');
    navigate('/packageTravel', { state: { tripId: data.id } });
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const travelHighlights = data.travel_highlights && data.travel_highlights.length >0 ? data.travel_highlights.split("//").filter(highlight => highlight.trim() !== ""):null;
  return (
    <>
    {!isMobile && (
    <div
      onClick={onClose}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 0', // Add top and bottom margins
        boxSizing: 'border-box',
      }}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          display: 'flex',
          width: '75%',
          maxHeight: 'calc(100% - 40px)', // Adjust for margins
          backgroundColor: 'white',
          borderRadius: '10px',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <img
          src={data.travel_cover_image}
          alt={data.text}
          style={{ width: '50%', objectFit: 'cover' }}
        />
        <div style={{ padding: '20px', overflowY: 'auto', width: '75%' }}>
          <IconButton
            onClick={onClose}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <h2>{data.travel_name}</h2>
          <p>
            {data.travel_description}
          </p>

          <div>
            {travelHighlights && travelHighlights !== null ? (
              <div>
                <p>
                  <strong>Highlights of the Trip:</strong>
                </p>
                <ul>
                    {travelHighlights.map((highlight, index) => (
                        <li key={index}>{highlight}</li>
                    ))}
                </ul>
              </div>
            ) : (
                <p></p> // You can adjust this fallback message as needed
            )}
          </div>
        </div>
        <Button
          variant="contained"
          sx={{
            position: 'absolute',
            right: 16,
            bottom: 16,
            backgroundColor: '#ff8000',
            '&:hover': {
              backgroundColor: '#ffa94d',
            },
            padding: '6px 12px', // Make the button smaller
            fontSize: '0.875rem', // Adjust the font size
          }}
          onClick={() => handleClick()}
        >
          Go to Trip
        </Button>
      </div>
    </div>
    )}
            {isMobile && (
                <div
                    onClick={onClose}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '20px 0', // Add top and bottom margins
                        boxSizing: 'border-box',
                    }}
                >
                    <div
                        onClick={(e) => e.stopPropagation()}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '90%',
                            maxHeight: 'calc(100% - 40px)', // Adjust for margins
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            position: 'relative',
                            overflow: 'hidden',
                            maxHeight: '80vh', // Set maximum height constraint for mobile view
                        }}
                    >
                        <img
                            src={data.travel_cover_image}
                            alt={data.text}
                            style={{ width: '100%', objectFit: 'cover' }}
                        />
                        <div style={{ padding: '20px', overflowY: 'auto', flex: 1 }}>
                            <IconButton
                                onClick={onClose}
                                aria-label="close"
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',  // Added background color
                                    zIndex: 10,  // Increased z-index
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <h2>{data.travel_name}</h2>
                            <p>{data.travel_description}</p>
                            <div>
                                {travelHighlights && travelHighlights !== null ? (
                                    <div>
                                        <p>
                                            <strong>Highlights of the Trip:</strong>
                                        </p>
                                        <ul>
                                            {travelHighlights.map((highlight, index) => (
                                                <li key={index}>{highlight}</li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <p></p> // You can adjust this fallback message as needed
                                )}
                            </div>
                        </div>
                        <Button
                            variant="contained"
                            sx={{
                                width: 'calc(100% - 32px)', // Adjust button width to be smaller than container width
                                margin: '0 16px 16px 16px', // Center button with margin
                                backgroundColor: '#ff8000',
                                '&:hover': {
                                    backgroundColor: '#ffa94d',
                                },
                                padding: '6px 12px', // Make the button smaller
                                fontSize: '0.875rem', // Adjust the font size
                                alignSelf: 'center', // Center the button horizontally
                            }}
                            onClick={() => handleClick()}
                        >
                            Go to Trip
                        </Button>
                    </div>
                </div>
            )}
    </>
  );

}

export default TraveldataPopup;
