import React, { Component } from 'react';

/* global google */

class Map extends Component {
    map = null;
    markers = [];
    mapNode = React.createRef();

    componentDidMount() {
        this.loadGoogleMapsScript();
    }

    loadGoogleMapsScript() {
        if (window.google && window.google.maps) {
                        this.initializeMap();
        } else if (!document.getElementById('googleMapsScript')) {
            const script = document.createElement('script');
            script.id = 'googleMapsScript';
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBuH3oyaXCDMtr-_jJNmGJtPBlbKDf9rVI`;
            script.async = true;
            script.defer = true;
            script.onload = () => this.initializeMap();
            document.head.appendChild(script);
        }
    }

    initializeMap = () => {
        try {
            const center = this.findCenter(this.props.coordinates);
            const mapOptions = {
                center: center,
                zoom: 12,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            };
            this.map = new google.maps.Map(this.mapNode.current, mapOptions);
            this.updateCoordinates(this.props.coordinates); // might need checking.
            this.addMarkersForPlaces(this.props.coordinates);
        } catch (error) {
            console.error("Error initializing the Google Maps:", error);
        }
    }

    updateCoordinates = (newCoordinates) => {       // Point map to a new given coordinates.
        if (!this.map) {
            console.error("Map not loaded yet!");
            return;
        }
        // Update map center
        this.map.setCenter(new google.maps.LatLng(newCoordinates.lat, newCoordinates.lng));
        this.markers.forEach(marker => marker.setMap(null));  // Clears existing markers
        this.markers = []; // Clears the markers array

        const marker = new google.maps.Marker({
            position: newCoordinates,
            map: this.map,
        });
        this.markers.push(marker); // Adds the new marker

        const bounds = new google.maps.LatLngBounds();
        newCoordinates.forEach(coords => {
            bounds.extend(new google.maps.LatLng(coords.lat, coords.lng));
        });

        const padding = this.props.padding || 0.015; 
        const sw = bounds.getSouthWest();
        const ne = bounds.getNorthEast();

        const extendedBounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(sw.lat() - padding, sw.lng() - padding),
            new google.maps.LatLng(ne.lat() + padding, ne.lng() + padding)
        );
        this.map.fitBounds(extendedBounds);
    }

    addMarkersForPlaces = (places, usePolyline) => {
        // Clear existing markers first
        this.markers.forEach(marker => marker.setMap(null));
        this.markers = [];

        // Optionally, clear existing polyline if it exists
        if (this.polyline) {
            this.polyline.setMap(null);
        }

        // Collect coordinates for polyline
        const pathCoordinates = [];

        // Add a new marker for each place
        places.forEach(place => {
            const position = { lat: place.lat, lng: place.lng };
            const marker = new google.maps.Marker({
                position: position,
                map: this.map,
                title: place.name // Optional: Add a label to each marker
            });
            this.markers.push(marker);
            pathCoordinates.push(position);
        });

        // Create a new polyline and add it to the map
        if (usePolyline){
            this.polyline = new google.maps.Polyline({
                path: pathCoordinates,
                geodesic: true,
                strokeColor: '#FF0000',
                strokeOpacity: 1.0,
                strokeWeight: 2
            });
    
            this.polyline.setMap(this.map);
        }

    }

    updateMarkers = (coordinates) => {
        this.markers.forEach(marker => marker.setMap(null));
        this.markers = [];
        try {
            const marker = new google.maps.Marker({
                position: coordinates,
                map: this.map,
            });
            this.markers.push(marker);
        } catch (error) {
            console.error("Error adding markers:", error);
        }
    }

    render() {
        return (
            <div ref={this.mapNode} style={{ height: '95vh', flexGrow: 1 }}>
                {/* Map will be attached here */}
            </div>
        );
    }

    findCenter(coordinates) {
        if (!coordinates.length) {
          return coordinates;
        }
      
        let totalLat = 0;
        let totalLng = 0;
      
        coordinates.forEach(coord => {
          totalLat += coord.lat;
          totalLng += coord.lng;
        });
      
        const centerLat = totalLat / coordinates.length;
        const centerLng = totalLng / coordinates.length;
      
        return { lat: centerLat, lng: centerLng };
      }
}

export default Map;
