import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FlightIcon from '@mui/icons-material/Flight';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import { useMediaQuery, useTheme } from '@mui/material';

const FlightsView = ({ flights }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleExpandClick = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  const formatRoutes = (routes) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {routes?.map((route, routeIndex) => (
          <div key={routeIndex} style={{ display: 'flex', alignItems: 'center' }}>
            {routeIndex === 0 ? `${route.cityFrom} (${route.flyFrom}) ` : ''}
            <FlightIcon style={{ fontSize: 16, transform: 'rotate(90deg)', color: 'gray', alignSelf: 'center', margin: '0 3px' }} />
            {` ${route.cityTo} (${route.flyTo})`}
            {routeIndex !== routes.length - 1 ? ' ' : ''}
          </div>
        ))}
      </div>
    );
  };

  const formatTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const formatDuration = (departureDateTime, arrivalDateTime) => {
    const departureTime = new Date(departureDateTime).getTime();
    const arrivalTime = new Date(arrivalDateTime).getTime();
    const durationMinutes = Math.abs(arrivalTime - departureTime) / (60 * 1000);
    const hours = Math.floor(durationMinutes / 60);
    const minutes = durationMinutes % 60;
    return `${hours}hr ${minutes}min`;
  };

  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('en-US', { month: 'long' }); // Use 'long' for full month name
    const weekday = date.toLocaleDateString('en-US', { weekday: 'long' });
    return `${day} ${month} ${weekday}`;
  };
    return (
    <div>
      {flights.map((flight, index) => (
        <Card key={index} style={{ margin: '10px 0' }}>
          <CardContent>
            <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row"}}>
                <Typography variant="body1" gutterBottom sx={{ flex: 5 }}>
                {formatRoutes(flight.routes)}
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ flex: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                {formatTime(flight?.local_departure)} &rarr; {formatTime(flight?.local_arrival)}
                <Box sx={{ display: 'flex', alignItems: 'center', color: 'gray', fontSize: 12 }}>
                  <AccessTimeIcon style={{ marginRight: 4, fontSize:14 }} /> {formatDuration(flight?.utc_departure, flight?.utc_arrival)}
                </Box>
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ flex: 2, display: "flex", justifyContent: isMobile ? "center" : "end", fontWeight: "bold" }}>
                    {flight?.price} {flight?.currency}
                </Typography>
            </Box>
            
          </CardContent>

          <Collapse in={expandedIndex === index} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Flight Details
              </Typography>
              {flight.routes?.map((route, routeIndex) => (
                <div key={routeIndex}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FlightTakeoffIcon style={{ fontSize: 16, color: 'gray', marginRight: 8 }} />
                    {isMobile 
                    ? <Box sx={{ display:"flex", flexDirection:"column", marginBottom:"10px" }}>
                        <Typography variant="body2">
                          {formatTime(route.local_departure)} - {formatDate(route.local_departure)}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {route.cityFrom}, {route.flyFrom} Airport
                        </Typography>
                      </Box>
                    : <Typography variant="body2" gutterBottom>
                        {formatTime(route.local_departure)} - {formatDate(route.local_departure)} - {route.cityFrom}, {route.flyFrom} Airport
                      </Typography>
                    }
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom:"10px" }}>
                    <FlightLandIcon style={{ fontSize: 16, color: 'gray', marginRight: 8 }} />
                    {isMobile 
                    ? <Box sx={{ display:"flex", flexDirection:"column" }}>
                        <Typography variant="body2" >
                          {formatTime(route.local_arrival)} - {formatDate(route.local_arrival)}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {route.cityTo}, {route.flyTo} Airport
                        </Typography>
                      </Box>
                    : <Typography variant="body2" gutterBottom>
                        {formatTime(route.local_arrival)} - {formatDate(route.local_arrival)} - {route.cityTo}, {route.flyTo} Airport
                      </Typography>
                    }
                  </Box>
                  <Typography variant="body2" gutterBottom sx={{ display: "flex", alignItems: "center" }}>
                        Flight Duration 
                        <AccessTimeIcon style={{ marginLeft: 10, marginRight: 4, fontSize:14 }} />
                        {formatDuration(route.utc_departure, route.utc_arrival)}
                   </Typography>
                   {routeIndex < flight.routes.length - 1 && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px 0' }}>
                        <hr style={{ border: 'none', borderBottom: '1px solid #ccc', width: '50%' }} />
                        <Typography variant="body2" style={{ margin: '0 5px', color: 'gray',whiteSpace: 'nowrap' }}>
                        Waiting Time: {formatDuration(route.utc_arrival, flight.routes[routeIndex + 1].utc_departure)}
                        </Typography>
                        <hr style={{ border: 'none', borderBottom: '1px solid #ccc', width: '50%' }} />
                    </div>
                    )}
                </div>
              ))}
              
            </CardContent>
          </Collapse>

          <CardActions disableSpacing sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              aria-expanded={expandedIndex === index}
              onClick={() => handleExpandClick(index)}
              sx={{ fontSize: "14px" }}
            >
                {expandedIndex === index ? "Show less" : "Show details"}
                {expandedIndex === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>
            <Button variant="contained" color="primary" href={flight?.deep_link} target="_blank">
              Visit Website
            </Button>
          </CardActions>
          
        </Card>
      ))}
    </div>
  );
};

export default FlightsView;
