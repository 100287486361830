import React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { Typography, Box, IconButton, Button, useMediaQuery, useTheme } from '@mui/material';
import Rating from '@mui/material/Rating';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const AccomodationsView = ({ accomodations }) => {
  const [scrollPosition, setScrollPosition] = React.useState(0); // Track scroll position

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderAccomodationCard = (accomodation, index) => (
    <Card
      key={index} // Add key prop for each card
      sx={{
        width: isMobile ? '80vw' : '20vw', // Set the width of each card to 25vw
        flex: '0 0 auto', // Prevent cards from growing to fill available space
        margin: '0 10px', // Add margin between cards
        boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)', // Vertical shadow only
        transition: 'transform 0.2s ease-in-out',
      }}
    >
      <CardMedia
        component="img"
        image={accomodation.cover_image}
        alt={accomodation.name}
        sx={{ objectFit: 'cover', height: 200 }}
      />
      <div style={{ padding: '10px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h7" style={{ marginBottom: '10px', marginRight: '10px' }}>{accomodation.name}</Typography>
            <Button
                variant="outlined"
                color="primary"
                onClick={() => window.open(accomodation.web_url, '_blank')}
                style={{ marginBottom: '5px', fontSize: '12px'  }}
            >
                Go to website
            </Button>
        </div>
        <Typography variant="body2" style={{ marginBottom: '5px', color: 'gray' }}>{accomodation.adress}</Typography>
        <Typography variant="body2" style={{ marginBottom: '5px', color: 'gray' }}>Style: {accomodation.styles.join(', ')}</Typography>
        {accomodation.priceLevel !== "" && (
            <Typography variant="body2" style={{ marginBottom: '5px', color: 'gray' }}>
                Price Level: {accomodation.price_level}
            </Typography>
        )}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Rating name="read-only" value={accomodation.rating} precision={0.1} readOnly />
          { accomodation.num_reviews 
            ? <Typography sx={{ paddingLeft: "10px", color: "gray", fontSize: "15px" }}>{accomodation.num_reviews} reviews</Typography>
            : <Typography sx={{ paddingLeft: "10px", color: "gray", fontSize: "15px" }}>({accomodation.rating})</Typography>
          }
        </div>
      </div>
    </Card>
  );
  

  const handleScroll = (scrollType) => {
    const container = document.getElementById('carousel-container');
    if (!container) return;
  
    const cardWidth = container.querySelector('div').clientWidth;
    const scrollAmount = scrollType === 'next' ? cardWidth + 20 : -cardWidth - 20;
  
    let newPosition = scrollPosition + scrollAmount;
  
    // Limit scroll position to prevent scrolling beyond limits
    if (newPosition < 0) {
      newPosition = 0; // Ensure newPosition doesn't go below 0
    } else {
      const maxScroll = container.scrollWidth - container.clientWidth;
      if (newPosition > maxScroll) {
        newPosition = maxScroll; // Ensure newPosition doesn't exceed maxScroll
      }
    }
  
    setScrollPosition(newPosition);
    container.scrollTo({ left: newPosition, behavior: 'smooth' });
  };
  

  return (
    <Box sx={{ display: 'flex', marginBottom: '20px', alignItems: 'center' }}>
      {!isMobile && 
        <IconButton
          onClick={() => handleScroll('prev')}
          sx={{
            flex: '1',
            maxWidth: '2vw',
            maxHeight: '2vw',
            transform: 'translateY(-50%)',
            marginRight: '10px',
            backgroundColor: 'lightGray',
            '&:hover': {
              backgroundColor: 'gray', // Change color on hover
            },
          }}
        >
          <NavigateBeforeIcon />
        </IconButton>
      }

      <Box
        id="carousel-container"
        sx={{
          flex: '10',
          padding: '20px 0',
          display: 'flex',
          overflowX: 'auto',
          WebkitOverflowScrolling: 'touch',
          scrollBehavior: 'smooth',
          scrollSnapType: 'x mandatory',
        }}
        style={{ scrollSnapAlign: 'start' }}
      >
        {accomodations.map((accomodation, index) => renderAccomodationCard(accomodation, index))}
      </Box>
      
      {!isMobile && 
        <IconButton
          onClick={() => handleScroll('next')}
          sx={{
            flex: '1',
            maxWidth: '2vw',
            maxHeight: '2vw',
            transform: 'translateY(-50%)',
            marginLeft: '10px',
            backgroundColor: 'lightGray',
            '&:hover': {
              backgroundColor: 'gray', // Change color on hover
            },
          }}
        >
          <NavigateNextIcon />
        </IconButton>
      }
    </Box>
  );
};

export default AccomodationsView;
