export function capitalizeFirstLetter(string) {
    if(string == null) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}


export function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };