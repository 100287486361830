import React, { useState } from 'react';
import PreferencesCard from './PreferencesCard';
import { useMediaQuery, useTheme } from '@mui/material';

const PreferencesSelector = ({ values, selectedOptions, onChange }) => {

  const handleOptionClick = (option) => {
    if (option === 'No Preference') {
      onChange(['No Preference']); // Select only 'No Preference' and deselect all others
    } else {
      const updatedOptions = selectedOptions.includes('No Preference')
        ? selectedOptions.filter((item) => item !== 'No Preference')
        : selectedOptions;

      onChange(updatedOptions.includes(option)
        ? updatedOptions.filter((item) => item !== option)
        : [...updatedOptions, option]
      );
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Adjust card size based on screen size
  const cardWidth = isMobile ? 150 : 200;
  const cardHeight = isMobile ? 100 : 140;

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: isMobile ? '95%' : '70%', gap: '20px', justifyContent:'center' }}>
      {values.map((value) => (
        <PreferencesCard
          key={value.text}
          text={value.text}
          isSelected={selectedOptions.includes(value.text)}
          onClick={() => handleOptionClick(value.text)}
          width={cardWidth}
          imageName={value.imageName}
          height={cardHeight}
        />
      ))}
    </div>
  );
}

export default PreferencesSelector;