import React from 'react';
import { Grid, Avatar } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';

const AvatarGrid = ({ onSelect, selectedId }) => {

    const avatars = [1,2,3,4,5,6,7,8,9];

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
      <Grid container spacing={2} sx={{ display:"flex", alignItems:"center", justifyContent:"center", width: isMobile ? "100%" : "500px" }}>
        {avatars.map((avatarId) => (
          <Grid item key={avatarId}>
            <Avatar 
              src={`/profilePictures/${avatarId}.png`} 
              onClick={() => onSelect(avatarId)}
              sx={{ 
                width: isMobile ? 100 : 120, 
                height: isMobile ? 100 : 120, 
                cursor: 'pointer',
                border: avatarId === selectedId ? '5px solid #ff8000' : '5px solid transparent',
                boxSizing: 'border-box'
              }} 
            />
          </Grid>
        ))}
      </Grid>
    );
};

export default AvatarGrid;