// SignUpPopup.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './SignUpPopup.css';
import api from '../api';

function SignUpPopup({ onClose }) {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
        alert("Passwords do not match");
        return;
    }
    api.post('/user/register/', { username, email, password })
       .then(() => {
           alert('Registration successful');
           setEmail('');
           setPassword('');
           setConfirmPassword('');
           onClose();
       })
       .catch(error => console.error('Registration error:', error));
};

  return (
    <div className="signup-popup-container">
      <div className="signup-overlay" onClick={onClose}></div>
      <div className="signup-popup">
        <div className="close-btn" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <h2>Sign Up</h2>
        <form onSubmit={handleSubmit}>
          <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          <input type="username" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required />
          <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
          <input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
          <div className="signup-options">
            <button className="submit-button" type="submit">Sign Up</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignUpPopup;
