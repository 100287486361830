import React, { useState, useEffect } from 'react';
import {Button } from "@mui/material";
import TripCard from './TripCard';
import api from '../../api';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { Grid } from '@mui/material';


const MyTrips = () => {

    const [tripData, setTripData] = useState([]);
    const navigate = useNavigate(); 

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleNavigate = () => {
      navigate('/'); 
    };

    useEffect(() => {
      const fetchTrips = async () => {
        try {
          const response = await api.get('/trip/'); // Assuming your API endpoint for trips is 'trips'
                    setTripData(response.data);
        } catch (error) {
          console.error('Error fetching trips:', error);
        }
      };
  
      fetchTrips();
    }, []); // Empty dependency array to run the effect only once

  const removeDeletedTrip = (deletedTripId) => {
    setTripData((prevData) => prevData.filter((trip) => trip.id !== deletedTripId));
  };


  if(tripData.length === 0) {
    return (
      <div style={{ 
        display:"flex", 
        alignItems:"center", 
        flexDirection: isMobile ? 'column' : 'row'
      }}>
        <img src="placeholders/tripPlaceholder.png" alt="description of image" style={{ maxHeight:"300px" }} />
        <div style={{ display:"flex", flexDirection:"column", alignItems:"start" }}>
        <h3 style={{ margin: "0 30px 0 30px"}}>Go ahead and let us</h3>
        <h3 style={{ margin: "0 30px 20px 30px"}}>plan your next trip!</h3>
        <div style={{ margin: "0 10px" }}>
            <Button variant="contained" sx={{ 
                mt: 'auto',
                alignSelf: 'center',
                bgcolor: '#ff8000', 
                '&:hover': { 
                  bgcolor: '#ffa94d' 
                } 
              }} onClick={handleNavigate}>
                Plan Your Next Travel
              </Button>
        </div>
        </div>
      </div>
    );
  }

  return (
    <Grid container spacing={2} justifyContent="center" style={{ width: '90vw' }}>
      {tripData.map((value) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={value.id}>
          <TripCard
            data={value}
            removeDeletedTrip={removeDeletedTrip}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default MyTrips;