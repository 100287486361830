import React from "react";
import Banner from "../components/Banner";
import SearchBar from "../components/SearchBar.js";
import Header from "../components/Header1.js";
import Footer from "../components/Footer.js";
import TravelPackagesRow from '../components/travelPackage/TravelPackagesRow';
import { Box, Typography } from '@mui/material';

const PopularTripsPage = () => {
    const travelPackages = [
        {id:1, text: 'Italy', content: 'Roma, Venezia, Torino', imageUrl: 'https://a.cdn-hotels.com/gdcs/production114/d887/3ef5277d-2cda-40d6-9bd5-3a7a1f7619fe.jpg' },
        {id:2, text: 'France', content: 'Paris, Marseiles, Versailes', imageUrl: 'https://cdn.pixabay.com/photo/2015/10/06/18/26/eiffel-tower-975004_640.jpg' },
        {id:3, text: 'Scandinavia', content: 'Norway, Finland, Iceland', imageUrl: 'https://i.natgeofe.com/n/22d43fba-6c4d-400d-b6fa-6a2300589c59/GettyImages-1446646686.jpg' },
    ]
  return (
    <>
      <div>
        <Header></Header>
      </div>
      <div style={{height: '100px'}}></div>
            <Box sx={{ width: '100%', margin:"15px 0px", display:"flex", flexDirection:"column", alignItems:"center" }}>
            <TravelPackagesRow values={travelPackages}/>
            </Box>
      <div style={{height: '100px'}}></div>
      <div>
        <Footer></Footer>
      </div>
    </>
  );
};

export default PopularTripsPage;