import React, {useState} from "react";
import { Card, CardContent, Grid, Avatar, Typography, Box, Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MapComponent from "./MapComponent.js";
import Map from "../travel/Map.js"
import { useRef } from "react";
import { capitalizeFirstLetter } from "../../helpers/helper.js";

const ProfileCard = ({ data }) => {
  const user = data.user;
  const userStats = data.user_stats;
  const coordinates = data.coordinates;

  const navigate = useNavigate(); 
  const mapRef = useRef(null);


  const handleNavigate = () => {
    navigate('/'); 
  };

  const handleEditProfileNavigate = () => {
    navigate('/edit-profile'); 
  };

  return (
    <Card sx={{ width: "80%", margin: "20px 0", textAlign: "center", boxShadow: 3 , backgroundColor:"lightgrey"}}>
      <CardContent>
        <Grid container spacing={2} justifyContent="center" alignItems="stretch" sx={{ height: "100%" }}>
          {/* Profile Info Part */}
          <Grid item xs={12} md={4} sx={{ display: "flex" }}>
            <Box sx={{
              borderRadius: 2, 
              backgroundColor: "background.default", 
              padding: 3, 
              boxShadow: 1, 
              display: "flex", 
              flexDirection: "column", 
              alignItems: "center", 
              flexGrow: 1,
              width: "100%"
            }}>
              <Avatar alt={user.username} src={user.profile.profile_picture ? `/profilePictures/${user.profile.profile_picture}.png` : "https://via.placeholder.com/150"} sx={{ width: 120, height: 120, mb: 2 }} />
              <Typography variant="h5" gutterBottom component="div">
                {user.first_name} {user.last_name}
              </Typography>
              <Typography 
                variant="subtitle1" 
                gutterBottom 
                component="div"
                sx={{
                  overflowWrap: 'break-word',
                  wordWrap: 'break-word',
                  hyphens: 'auto',
                  maxHeight: '4.5em', // Adjust as needed
                  overflow: 'hidden',
                }}
              >
                {user.profile.about}
              </Typography>
              <Button variant="contained" sx={{ 
                mt: 'auto', 
                bgcolor: '#ff8000', 
                '&:hover': { 
                  bgcolor: '#ffa94d' 
                }
              }} onClick={handleEditProfileNavigate}>
                Edit Profile
              </Button>
            </Box>
          </Grid>
          {/* Stats Part */}
          <Grid item xs={12} md={4} sx={{ display: "flex", justifyContent: "space-between"}}>
            <Box sx={{
              borderRadius: 2, 
              backgroundColor: "background.default", 
              padding: 3, 
              boxShadow: 1, 
              display: "flex", 
              flexDirection: "column", 
              flexGrow: 1,
              width: "100%"
            }}>
              <Typography variant="h6" gutterBottom component="div">
                Travel Statistics
              </Typography>
              { userStats.total_trips == 0 
                ? <Typography variant="body1" gutterBottom component="div">No trips yet</Typography>
                : <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <StatCard label="Total Countries Visited" value={userStats.visited_country_count} />
                      <StatCard label="Total Cities Visited" value={userStats.visited_city_count} />
                      <StatCard label="Most Visited City" value={capitalizeFirstLetter(userStats.most_visited_city)} />
                    </Grid>
                    <Grid item xs={6}>
                      <StatCard label="Total Trip Count" value={userStats.total_trips} />
                      <StatCard label="Total Days Traveled" value={userStats.total_days} />
                      <StatCard label="Total Kilometers Traveled" value={2} />
                    </Grid>
                  </Grid> }
              <Button variant="contained" sx={{ 
                mt: 'auto',
                alignSelf: 'center',
                bgcolor: '#ff8000', 
                '&:hover': { 
                  bgcolor: '#ffa94d' 
                } 
              }} onClick={handleNavigate}>
                Plan Your Next Travel
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: "flex" }}>
            <Box sx={{
              borderRadius: 2, 
              backgroundColor: "background.default", 
              padding: 1, 
              boxShadow: 1, 
              display: "flex", 
              flexDirection: "column", 
              alignItems: "center", 
              flexGrow: 1,
              width: "100%"
            }}>
              <Typography variant="h6" sx={{margin: 0}}>Visited Places</Typography>
              {/*<MapComponent apiKey="" cities={user.citiesVisited} />*/}
              <Box sx={{ height: "300px", width:"100%", overflow:"hidden", display:"flex", alignItems:"center", justifyContent:"center" }}>
                { userStats.total_trips == 0 
                ? <Box>
                    <img src="placeholders/mapPlaceholder.png" alt="description of image" style={{ maxHeight:"200px" }} />
                    <Typography variant="body1" gutterBottom component="div">You will see your trips here</Typography>
                  </Box>
                : <Map ref={mapRef} coordinates={coordinates} mode={'view'} place={'deneme'} padding={1}/> }  
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProfileCard;


const StatCard = ({ label, value }) => (
  <Card sx={{ margin: "10px 0", padding:"5px", boxShadow: 3 }}>
    <Typography variant="body1" gutterBottom component="div">
      {label}
    </Typography>
    <Typography variant="body1" gutterBottom component="div" sx={{ fontWeight:"bold" }}>
      {value}
    </Typography>
  </Card>
);